import React, {Component} from 'react';
import { Container,Row,Breadcrumb,Col,Carousel } from 'react-bootstrap';
import img from "./images/physicalLocation-other-1_r1gWFDfpYm.jpg";

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state={
            detail_login: this.props.detail_login ? this.props.detail_login : null,
        }
    }
    componentDidMount(){
        console.log("detail_login",this.state.detail_login)
    }
    render(){
        return(
    <Col md={12} style={{ marginLeft: '30px'}}>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>BIO MEDIKA</h3>
              <p>Booking MCU</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img}
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3>BIO MEDIKA</h3>
              <p>Booking MCU</p>
            </Carousel.Caption>
          </Carousel.Item>
         

        </Carousel>
      </Col>
        )
    }
}
export default Dashboard;