import React, { Component } from "react";
import bg1 from "./images/bg1.png";
import "./assets/login.scss";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { uAPI } from "./config/Config";
import Header from "./Header";
import swal from "sweetalert";
import queryString from "query-string";
import { find } from "lodash";
import "./assets/timepicker.css";
import moment from "moment";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const format = "HH:mm";
const animatedComponents = makeAnimated();

class Book extends Component {
 constructor(props) {
  super(props);
  this.state = {
   email:"",
   print: "",
   redirect: "",
   patient_id: "",
   jam_mulai: "",
   tgl_periksa: "",
   hari:"",
   sisa_slot:"",
   patient_name: "",
   email:"",
   id_pendaftaran:"",
   ktp:"",
   display: "none",
   disabled: "none",
   sisa_kuota: [],
   kuota:0,
   jumlah_pendaftar: 0,
   cabang:[],
   option_cabang: [],
   branch_id:"",
   select_cabang:"",
   captha:"",
   disabledHours: [],
   disabledHolidays: [],
   query: queryString.parse(this.props.location.search),
   tambahPeserta: [],
   slotTerpakai: 1,
   hubungan: 'Utama',
   namaLengkap:'',
   gender: "",
  selectedGender: "",
  tgl: "",
  bln: "",
  thn: "",
  address: "",
  tgl_periksa2: "",
  };

  this.onChangeTanggal = this.onChangeTanggal.bind(this);
  this.checkEmail = this.checkEmail.bind(this);
  this.onChange = this.onChange.bind(this);
  this.saveBook = this.saveBook.bind(this);
  this.getHolidays = this.getHolidays.bind(this);
  this.tambahPeserta = this.tambahPeserta.bind(this);
  this.tambahPesertaChange = this.tambahPesertaChange.bind(this);
  this.removePeserta = this.removePeserta.bind(this);
  this.thnTambahPesertaChange = this.thnTambahPesertaChange.bind(this)
 }

 loginError = async (msg) => await toast.error(msg, {
  position: "bottom-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  onClose: () => this.setState({
      loader: false,
  })
});
loginSuccess = async (msg) => await toast.success(msg, {
  position: "bottom-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  onClose: () => this.setState({
      loader: false,
      login: true,
  })
});

 onChange(e) {
  this.setState({
   [e.target.name]: e.target.value,
  });
 }

 

 async saveBook(e) {

  console.log(this.state.tambahPeserta)

  let user_captcha = document.getElementById('captha').value;

  if (validateCaptcha(user_captcha)==true) {
    loadCaptchaEnginge(6,'white','red','numbers');
          document.getElementById('captha').value = "";
      if (this.state.kuota == 0){
        // await this.loginError('Mohon maaf untuk saat ini slot penuh!');
         alert("Mohon maaf untuk saat ini slot penuh!");
     
       }
     
       else {
     
     
       await this.setState({
        loader: true,
       });
     
       e.preventDefault();
      

     
       const formData = new FormData();
       const config = {
        headers: {
         "content-type": "multipart/form-data",
        },
       };
       formData.append("patient_id", this.state.patient_id);
       formData.append("branch_id", this.state.branch_id);
       formData.append("branch_name", this.state.select_cabang);
       formData.append("tgl_periksa", moment(this.state.tgl_periksa, "YYYY-MM-DD").format("YYYY-MM-DD"));
       formData.append("patient_name", this.state.patient_name);
       formData.append("email", this.state.email);
       formData.append("id_pendaftaran", this.state.id_pendaftaran);
       formData.append("ktp", this.state.ktp);
       


       const hubungan = [];
       await hubungan.push(
           this.state.tambahPeserta.hubungan,
       )
       if (this.state.tambahPeserta.length > 0) {
           this.state.tambahPeserta.map(obj => (
               hubungan.push(
                   obj.hubungan,
               )
           ))
       }

       const valHubungan = hubungan.filter(el => { return el != null });
        console.log(valHubungan);
        formData.append('hubungan', valHubungan);



      const namaLengkap = [];
       await namaLengkap.push(
           this.state.tambahPeserta.namaLengkap,
       )
       if (this.state.tambahPeserta.length > 0) {
           this.state.tambahPeserta.map(obj => (
               namaLengkap.push(
                   obj.namaLengkap,
               )
           ))
       }

       const valNamaLengkap = namaLengkap.filter(el => { return el != null });
        console.log(valNamaLengkap);
        formData.append('namaLengkap', valNamaLengkap);


      const gender = [];
       await gender.push(
           this.state.tambahPeserta.gender,
       )
       if (this.state.tambahPeserta.length > 0) {
           this.state.tambahPeserta.map(obj => (
               gender.push(
                   obj.gender,
               )
           ))
       }

       const valGender = gender.filter(el => { return el != null });
        console.log(valGender);
        formData.append('gender', valGender);


        const valuesBirthDate = [];
      
       
            this.state.tambahPeserta.map(obj => (
                valuesBirthDate.push(
                    `${obj.tgl}-${obj.bln}-${obj.thn}`,
                )
            ))
        
        const valBirthDate = valuesBirthDate.filter(el => { return el != null });
        console.log(valBirthDate);
        formData.append('valuesBirthDate', valBirthDate);

        const address = [];
        await address.push(
            this.state.tambahPeserta.address,
        )
        if (this.state.tambahPeserta.length > 0) {
            this.state.tambahPeserta.map(obj => (
                address.push(
                    obj.address,
                )
            ))
        }
 
        const valAddress = address.filter(el => { return el != null });
         console.log(valAddress);
         formData.append('address', valAddress);
        


       
       
       let validation = false;
       
        if (
         formData.get("patient_id") !== null ||
         formData.get("patient_id") !== ""
        ) {
         await axios
          .put(uAPI + "/api/v1/book", formData, config)
          .then((response) => {
           if (response.data.status === 406) {
            this.setState({
             loader: false,
             patient_id: "",
             errorMessage: response.data.message,
            });
           } else {
            this.setState({
             loader: false,
             patient_id: "",
             errorMessage: response.data.message,
            });
           }
     
           swal({
            title: "Booking",
            text: "Data telah di simpan!",
            icon: "success",
            timer: 6000,
            button: "Terima Kasih!",
           });
     
           setTimeout(window.location.reload.bind(window.location), 2000);
          })
          .catch((error) => {
           console.log(error);
           this.setState({
            loader: false,
            patient_id: "",
            errorMessage: "Terjadi Kesalahan. Silahkan dicoba lagi.",
           });
          });
        }
       }
       e.target.reset();
       localStorage.removeItem("tambahPeserta");
  }

  else {
      alert('Captcha salah !');
      document.getElementById('user_captcha_input').value = "";
  }

  
 }

 async onChangeTanggal(selectedDay, modifiers, dayPickerInput) {
  
  const input = dayPickerInput.getInput().value;
  const hari = moment(input).format('dddd');
  
  this.setState({
   tgl_periksa: moment(input, "YYYY-MM-DD").format("YYYY-MM-DD"),
   disabled: "block",
   disabledHours: [],
  });

  

  const formData = new FormData();
  const config = {
  headers: {
  "content-type": "multipart/form-data",
  },
  };

    
    formData.append("branch_id", this.state.branch_id);
    formData.append("hari", hari);

    console.log('hari',hari);

    await axios
    .post(uAPI + "/api/v1/listkuota", formData, config)
    .then(function (response) {
    return response.data.results;
    })
    .then((response) => {
      this.setState({
        sisa_kuota: response
       });
     })
     .catch(function (error) {
      console.log(error);
     });

    

     const formData2 = new FormData();
     formData2.append("tgl_periksa", moment(input, "YYYY-MM-DD").format("YYYY-MM-DD"));
     formData2.append("branch_id", this.state.branch_id);
   
    await axios
    .post(uAPI + "/api/v1/checkpendaftaran", formData2, config)
    .then(function (response) {
    return response.data.results;
    })
    .then((response) => {
  //  console.log(response);
    
    this.setState(
    {
    jumlah_pendaftar: response.length,
    
    },
    () => console.log("jmlpendaftar",this.state.jumlah_pendaftar)
    );
    })
    .catch(function (error) {
    console.log(error);
    });

    if(this.state.sisa_kuota > '') {

    {this.state.sisa_kuota.map(item => (


      this.setState({
      
        kuota: item.kuota - this.state.jumlah_pendaftar
        
       })

      )
      
      
      
      )};

      }

      else{

        this.setState({
      
          kuota: 0
          
         })


      }

    }


 async getHolidays() {
 // console.log(this.state.patient_id);
  //console.log(this.state.tgl_periksa);
 // console.log(this.state.jam_mulai);
 // console.log(moment("14:00", "H:mm").locale('en').format('h:mm a'))
 // console.log("ss")
  let r = [];
  await axios
   .get(
    "https://www.googleapis.com/calendar/v3/calendars/id.indonesian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyDcnW6WejpTOCffshGDDb4neIrXVUA1EAE"
   )
   .then(function (response) {
   // console.log(response);

    response.data.items.map(function (v, k) {
     if (v.description == "Hari libur nasional") {
      // console.log(v.summary)
    //  console.log('libur',v.start.date)
      r.push(v.start.date);
     }
    });
   })
   .catch(function (error) {
    console.log(error);
   });

  // let j = [];
  // await axios
  // .get(uAPI + "/api/v1/checkjadwal")
  //  .then(function (response) {
  //   console.log(response);

  //   response.data.items.map(function (v, k) {
  //    if (v.description == "Hari libur nasional") {
  //     // console.log(v.summary)
  //   //  console.log('libur',v.start.date)
  //     r.push(v.start.date);
  //    }
  //   });
  //  })
  //  .catch(function (error) {
  //   console.log(error);
  //  });

  this.setState({
   disabledHolidays: r,
  });
 }

 changeHandler = (e) => {
 // console.log(e.branch_id);
  //console.log(e.branch_name);
  this.setState({ branch_id: e.branch_id,select_cabang:e.branch_name});
 };

 async checkEmail(event) {
  event.preventDefault();

       const formData = new FormData();
       const config = {
        headers: {
         "content-type": "multipart/form-data",
        },
       };
       formData.append("email", this.state.email);

  await axios
   .post(uAPI + "/api/v1/checkemail", formData, config)
   .then(function (response) {
    return response.data.results;
   })
   .then((response) => {
    const isUserValid = response.some((user) => {
     return user.email === event.target.email.value;
    });
    // console.log(isUserValid);
    if (isUserValid === true) {
     var result = find(response, function (o) {
      return o.email === event.target.email.value;
     });

     this.setState({
      patient_id: result.patient_id,
      patient_name: result.nama,
      email: result.email,
      id_pendaftaran: result.id_pendaftaran,
      ktp:result.ktp,
      display: "block",
     });

     loadCaptchaEnginge(6,'white','red','numbers');
     //  console.log(this.state.patient_id);
    } else {
     alert("data Email tidak ditemukan");
     this.setState({
      display: "none",
     });
    }
   })
   .catch(function (error) {
    console.log(error);
   });
   
 }

 async tambahPeserta() {
  console.log('tambah form lagi')
        await this.setState({
            slotTerpakai: this.state.slotTerpakai + 1,
            tambahPeserta: [
                ...this.state.tambahPeserta,
                {
                    selectedHubungan: null,
                    hubungan: null,
                    namaLengkap: null,
                    gender: null,
                    selectedGender: null,
                    tgl: null,
                    bln: null,
                    thn: null,
                    alamat:null,
                    valuesBirthDate:null,
                }
            ]
        }, () => console.log(this.state.tambahPeserta));
        localStorage.setItem("slotTerpakai", JSON.stringify(this.state.slotTerpakai));
        await localStorage.setItem("tambahPeserta", JSON.stringify(this.state.tambahPeserta));
        
}

async removePeserta(target) {
  var array = [...this.state.tambahPeserta];
  var index = array.indexOf(array[target]);
  if (index !== -1) {
      array.splice(index, 1);
      await this.setState({
          tambahPeserta: array,
          slotTerpakai: this.state.slotTerpakai - 1,
      }, () => console.log(this.state.tambahPeserta));
      localStorage.setItem("slotTerpakai", JSON.stringify(this.state.slotTerpakai));
      await localStorage.setItem("tambahPeserta", JSON.stringify(this.state.tambahPeserta));
  }
}

hubunganChange = async (selected, e) => {
  let tambahPeserta = [...this.state.tambahPeserta];
  let getIndex = e.name.split("-");
  let curIndex = getIndex[1];
  tambahPeserta[curIndex] = {
      ...tambahPeserta[curIndex],
      hubungan: selected.value,
      selectedHubungan: selected,
  };
  await this.setState({
      tambahPeserta
  }, () => console.log(this.state.tambahPeserta));
  await localStorage.setItem("tambahPeserta", JSON.stringify(this.state.tambahPeserta));

};
optionsHubungan = [
  { value: 'Ayah', label: 'Ayah' },
  { value: 'Ibu', label: 'Ibu' },
  { value: 'Anak', label: 'Anak' },
  { value: 'Suami', label: 'Suami' },
  { value: 'Istri', label: 'Istri' },
  { value: 'Keluarga-Lain', label: 'Keluarga Lain' },
];

async tambahPesertaChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let getIndex = name.split("-");
    let curIndex = getIndex[1];
    let curName = getIndex[0];

    let tambahPeserta = [...this.state.tambahPeserta];
    tambahPeserta[curIndex] = {
        ...tambahPeserta[curIndex],
        [curName]: value
    };
    await this.setState({
        tambahPeserta
    }, () => console.log(this.state.tambahPeserta));
    await localStorage.setItem("tambahPeserta", JSON.stringify(this.state.tambahPeserta));

}

optionsGender = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

genderTambahPesertaChange = async (selected, e) => {
  let tambahPeserta = [...this.state.tambahPeserta];
  let getIndex = e.name.split("-");
  let curIndex = getIndex[1];
  tambahPeserta[curIndex] = {
      ...tambahPeserta[curIndex],
      gender: selected.value,
      selectedGender: selected,
  };
  await this.setState({
      tambahPeserta
  }, () => console.log(this.state.tambahPeserta));
  await localStorage.setItem("tambahPeserta", JSON.stringify(this.state.tambahPeserta));
};

thnTambahPesertaChange(e) {
  let name = e.target.name;
  var val = e.target.value;
  let value = e.target.value;
  let getIndex = name.split("-");
  let curIndex = getIndex[1];
  let curName = getIndex[0];

  if (val.length === 4) {
      val = getAge(`${this.state.bln}/${this.state.tgl}/${val}`) < 18;
      console.log(val)
      if (val) {
          let tambahPeserta = [...this.state.tambahPeserta];
          tambahPeserta[curIndex] = {
              ...tambahPeserta[curIndex],
              [curName]: ""
          };
          this.setState({
              modalTambah: false,
              modalAlert: true,
              modalAlertMessage: "Umur Peserta Lain tidak boleh dibawah 18 tahun!",
              tambahPeserta
          }, () => console.log(this.state.tambahPeserta))
      } else {
          let tambahPeserta = [...this.state.tambahPeserta];
          tambahPeserta[curIndex] = {
              ...tambahPeserta[curIndex],
              [curName]: value
          };
          this.setState({
              tambahPeserta
          }, () => console.log(this.state.tambahPeserta));
      }
  } else {
      let tambahPeserta = [...this.state.tambahPeserta];
      tambahPeserta[curIndex] = {
          ...tambahPeserta[curIndex],
          [curName]: value
      };
      this.setState({
          tambahPeserta
      }, () => console.log(this.state.tambahPeserta));
  }


  function getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }

    return age;
}


}

async componentDidMount() {

var ambilTambahPeserta = localStorage.getItem('tambahPeserta');
if (ambilTambahPeserta) {
    this.setState({
        tambahPeserta: JSON.parse(ambilTambahPeserta)
    })
}
const option_cabang = []

await axios
.get(uAPI + "/api/v1/cabang")
.then(function (response) {
return response.data.results;
})
.then((response) => {

this.setState({
option_cabang : response

});
})
.catch(function (error) {
console.log(error);
});


  //  console.log('cabang',this.state.option_cabang)


  try {
    setInterval(async () => {
    //  console.log("kuota", this.state.sisa_kuota)
    
    
    }, 1000);
  } catch(e) {
    console.log(e);
  }

  
  this.getHolidays();
 }

 render() {
  const data = this.state.print;
  const k = this.state.sisa_kuota;
  const modifiersStyles = {
   daysOfWeek_: {
    color: "red",
    backgroundColor: "white",
   },
   holidays_: {
    color: "red",
    backgroundColor: "white",
   },
   selectedDays: {
    color: "white",
    backgroundColor: "#00A551",
   },
  };
  return (
   <>
    <Header />
    <div className="container-fluid">
     <div className="row no-gutter">
      <div className="col-md-6">
       <h4
        style={{
         fontSize: "70px",
         fontWeight: "bold",
         marginBottom: "20px",
         marginTop: "80px",
         marginLeft: "10px",
         lineHeight: "70px",
         fontFamily: "Tahoma,Verdana,Segoe,sans-serif",
        }}
       >
        Booking <br />
        MCU
        <br />
       </h4>
       <img
        className="d-none d-sm-none d-md-none d-lg-block"
        src={bg1}
        width="100%"
        style={{ marginBottom: "20px" }}
        alt=""
       />
      </div>
      <div className="col-md-6">
       <div className="login d-flex align-items-center py-5">
        <div className="container">
         <div className="row">
          <div className="col-lg-10 col-xl-7 mx-auto"
          style={{
            backgroundColor: 'rgba(255,255,255, 0.5)',
            fontWeight: "bold",
            padding:20,
            borderRadius:25,
           }}
          >
           <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
            Pilih Tanggal kedatangan
           </h1>
           <br /> <br /> <br />
           <form onSubmit={this.checkEmail}>
            <div className="text-center d-flex justify-content-between">
             <p
              style={{
               fontSize: "14px",
               fontWeight: "bold",
              }}
             >
              Masukkan Email
              (menggunakan huruf kecil)
             </p>
            </div>

            <input
             onChange={this.onChange}
             type="email"
             placeholder="EMAIL"
             required
             autofocus
             name="email"
             value={this.state.email}
             className="form-control rounded-pill border-0 shadow-sm px-4"
             style={{ backgroundColor: "#c9d9dd!important" }}
            />
            <br />
            <div className="col-md-12">
             <div className="submit-button text-center">
              {this.state.loader ? (
               <Loader type="Circles" color="#00BFFF" height={40} width={100} />
              ) : (
               <button
                style={{
                 backgroundColor: "#c9d9dd",
                 borderColor: "#c9d9dd",
                 color: "#000",
                 fontWeight: "bold",
                }}
                className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm"
                id="submit"
                type="submit"
               >
                Check Email
               </button>
              )}
             </div>
            </div>
           </form>
           <br /> <br /> <br />
           <form
            onSubmit={this.saveBook}
            style={
             this.state.showMyComponent ? {} : { display: this.state.display }
            }
           >
            <div className="text-center d-flex justify-content-between">
             <p style={{ fontSize: "14px", fontWeight: "bold" }}>Nama Anda</p>
            </div>
            <input
             id="inputEmail"
             type="text"
             value={this.state.patient_name}
             readonly="true"
             className="form-control rounded-pill border-0 shadow-sm px-4"
             style={{ backgroundColor: "#c9d9dd!important" }}
            />
           
            <input
             id="inputEmail"
             name="patient_id"
             type="hidden"
             value={this.state.patient_id}
             readonly="true"
             className="form-control rounded-pill border-0 shadow-sm px-4"
             style={{ backgroundColor: "#c9d9dd!important" }}
            />
            <br />

            <div className="text-center d-flex justify-content-between mt-4">
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>Pilih Cabang</p>
            </div>
             
             <Select
              name="cabang"
              options={this.state.option_cabang}
              onChange={this.changeHandler}
              getOptionLabel={(option_cabang) =>
               option_cabang.branch_name
              }
              getOptionValue={(option_cabang) => option_cabang.branch_id}
              required="true"
              components={animatedComponents}
             />

             <br />

            <div className="form-group mb-3">
             <div className="text-center d-flex justify-content-between">
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>Tanggal Periksa</p>
             </div>

             <DayPickerInput
             style={{cursor:"pointer"}}
              name="tgl_periksa"
              inputProps={{
               className: "form-control rounded-pill border-0 shadow-sm px-4",
               required: true,
              //  readOnly: true
              }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              onDayChange={this.onChangeTanggal}
              // dayPickerProps={{
              //  // todayButton: 'Today',
              //  modifiers: {
              //   selectedDays: new Date(),
              //   daysOfWeek_: { daysOfWeek: [0] },
              //   holidays_: [
              //    ...this.state.disabledHolidays.map((day) => new Date(day)),
              //   ],
              //   disabled: [
              //    { after: new Date(2021, 10, 29) },
              //    { daysOfWeek: [0] },
              //    { before: new Date() },
              //    ...this.state.disabledHolidays.map((day) => new Date(day)),
              //   ],
              //  },
              //  modifiersStyles: modifiersStyles,
              // }}
             />
               <br /> <br />

               <div className="col-md-12">
             <div className="submit-button text-center">
              {this.state.loader ? (
               <Loader type="Circles" color="#00BFFF" height={40} width={100} />
              ) : (
               <button
                style={{
                 backgroundColor: "#c9d9dd",
                 borderColor: "#c9d9dd",
                 color: "#000",
                 fontWeight: "bold",
                }}
                onClick={this.tambahPeserta}
                className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm"
               >
                Tambah Peserta
               </button>
              )}
             </div>
            </div>


            {
                                this.state.tambahPeserta.map((val, index) => {

                                    return (
                                        <div key={index}>
                                            <br />
                                            <br />
                                            <div className="row row-sm form-group">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <div className="row row-sm col-sm-12 col-md-12 col-lg-12">
                                                        <div className='col-md-12 col-lg-6 col-sm-12'>
                                                            <h3>Peserta Tambahan:  </h3>
                                                        </div>
                                                        <div className='col-md-12 col-lg-6 col-sm-12'>
                                                            <button type="button" className="btn btn-danger btn-block" onClick={() => this.removePeserta(index)}>Hapus</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3"></div>
                                                <div className="col-sm-3 col-md-3 col-lg-3">
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3">
                                                    {/* <button type="submit" className="btn btn-primary btn-block">Lanjut</button> */}
                                                </div>
                                            </div>

                                            <div className="row row-sm form-group">
                                                <div className="col-md-12 col-lg-12 mg-t-20 mg-md-t-0">
                                                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>Hubungan</p>
                                                    <Select
                                                        name={`hubungan-${index}`}
                                                        placeholder='Pilih Hubungan'
                                                        value={this.state.tambahPeserta[index].selectedHubungan}
                                                        onChange={this.hubunganChange}
                                                        options={this.optionsHubungan}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row row-sm form-group">
                                                <div className="col-md-12 col-lg-12">
                                                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>Nama Lengkap</p>
                                                    <input className="form-control" name={`namaLengkap-${index}`} type="text" value={this.state.tambahPeserta[index].namaLengkap} placeholder="Masukkan Nama Lengkap" onChange={this.tambahPesertaChange} />
                                                </div>
                                            </div>
                                            <div className="row row-sm form-group">
                                                <div className="col-md-6 col-lg-6">
                                                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>Jenis Kelamin</p>
                                                    <Select
                                                        name={`gender-${index}`}
                                                        placeholder='Pilih Jenis Kelamin'
                                                        value={this.state.tambahPeserta[index].selectedGender}
                                                        onChange={this.genderTambahPesertaChange}
                                                        options={this.optionsGender}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                                                <label className="form-control-label">Tanggal Lahir: <span className="tx-danger">*</span></label>
                                                    <div className="row row-sm col-sm-12 col-md-12 col-lg-12">
                                                        <div className='col-md-12 col-lg-12 col-sm-12'>
                                                            <input className="form-control"  name={`tgl-${index}`} type="tel" min='1' maxLength={2} value={this.state.tambahPeserta[index].tgl} placeholder="DD (01)" onChange={this.tambahPesertaChange} />
                                                            <input className="form-control" name={`bln-${index}`} type="tel" format={"mm"} min='1' maxLength={2} value={this.state.tambahPeserta[index].bln} placeholder="MM (02)" onChange={this.tambahPesertaChange} />
                                                            <input className="form-control" name={`thn-${index}`} type="tel" min='1' maxLength={4} value={this.state.tambahPeserta[index].thn} placeholder="YYYY (2021)" onChange={this.thnTambahPesertaChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div className="form-group">
                                                <p style={{ fontSize: "14px", fontWeight: "bold" }}>Alamat</p>
                                                <textarea className="form-control rounded-pill border-0 shadow-sm px-4" name={`address-${index}`} value={this.state.tambahPeserta[index].address} onChange={this.tambahPesertaChange}></textarea>
                                            </div>
                                            
                                            
                                        </div>
                                    )
                                })
                            }
                            <br/>
                              
                    <div className="text-center d-flex justify-content-between">
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>Jumlah Pendaftar</p>
                    </div>
                    <input
                    id="inputEmail"
                    type="text"
                    value={this.state.jumlah_pendaftar}
                    readonly="true"
                    className="form-control rounded-pill border-0 shadow-sm px-4"
                    style={{ backgroundColor: "#c9d9dd!important" }}
                    />

                    <div className="text-center d-flex justify-content-between mt-4">
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>Sisa Slot</p>
                    </div> 
                    
                    <input
                    id="inputEmail"
                    type="text"
                    value={this.state.kuota}
                    readonly="true"
                    className="form-control rounded-pill border-0 shadow-sm px-4"
                    style={{ backgroundColor: "#c9d9dd!important" }}
                    />
                      
                  <br />
                  
                  <input
                        id="captha"
                        name="captha"
                        type="text"
                        placeholder="Enter Captcha"
                        required
                        autofocus
                        className="form-control rounded-pill border-0 shadow-sm px-4"
                        style={{ backgroundColor: "#c9d9dd!important" }}
                        autoComplete="off"
                        onChange={this.onChange}
                      />
                          <br />
                      <LoadCanvasTemplate />
                    

                    </div>

            <div className="col-md-12">
             <div className="submit-button text-center">
              {this.state.loader ? (
               <Loader type="Circles" color="#00BFFF" height={40} width={100} />
              ) : (
               <button
                style={{
                 backgroundColor: "#c9d9dd",
                 borderColor: "#c9d9dd",
                 color: "#000",
                 fontWeight: "bold",
                }}
                className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm"
                id="submit"
                type="submit"
               >
                Kirim
               </button>
              )}
             </div>
            </div>
           </form>
           {/* <button onClick={this.getHolidays}> testing </button> */}
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </>
  );
 }
}
export default Book;
