import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./assets/style.css";
import Header from "./Header";
import Sidebar from "./Sidebar";




class Index extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
          <title>Bio Medika MCU - Admin</title>
          <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
          <meta name="robots" content="noindex, nofollow" />
          <meta name="googlebot" content="noindex, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <body className="background2" />
        </Helmet>
        <Header />
        <Sidebar />
      </React.Fragment>
    );
  }
}

export default Index;
