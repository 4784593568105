import React, { Component } from "react";
import Login from "../Login";
import Scan from "../Scan";
import Book from "../Book";
import BookNew from "../Book-New";
import Display from "../Display";
import Mobile from "../Mobile";
import Admin from "../admin/Index";
import printKartu from '../admin/printKartu';
import printScreening from '../admin/printScreening';
import "../assets/style.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
           <Route path="/" exact component={Login} />
           <Route path="/admin" component={Admin} />
           <Route path="/print-kartu" component={printKartu} />
           <Route path="/print-screening" component={printScreening} />
        </Switch>

        <Switch>

            <Route path="/scan" exact component={Scan} />
            <Route path="/book" exact component={Book} />
            <Route path="/book-new" exact component={BookNew} />
            <Route path="/display" exact component={Display} />
            <Route path="/mobile" exact component={Mobile} />
            
        </Switch>

      
      </BrowserRouter>
    );
  }
}

export default Router;
