    import React, {Component,Fragment} from 'react';
    import { DataGrid, Dialog, Form, FormField, GridColumn, Layout, LayoutPanel, LinkButton, Panel, TextBox,ComboBox,CheckBox,DateBox,TimePicker} from 'rc-easyui';
    import { Container,Row,Breadcrumb,Col,Card,Button,Tabs,Tab,Alert} from 'react-bootstrap';
    import './index.scss';
    import { uAPI } from '../config/Config';
    import axios from 'axios';
    import QRCode from 'qrcode'
    import Loader from 'react-loader-spinner';
    import { createWorker } from 'tesseract.js';
    import { FilePond, registerPlugin } from 'react-filepond';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
    import 'filepond/dist/filepond.min.css';
    import { Camera } from 'react-cam';
    import CSVReader from "react-csv-reader";
    import { Redirect,browserHistory,Link } from 'react-router-dom';
    import jsPDF from "jspdf";
    import Speech from 'react-speech';
    import $ from "jquery";
    import parse from "html-react-parser";
    import Clipboard from 'react-clipboard.js';
    import DayPicker from "react-day-picker";
    import DayPickerInput from "react-day-picker/DayPickerInput";
    
   


    function getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }

    return age;
    }



    class Pendaftaran extends Component{
    constructor(props){
        super(props);
        this.state={
        login: this.props.login,
        detail_login: this.props.detail_login,
        loading: false,
        error: false,
        print: '',
        status:'',
        pendaftaran: {
            total: 0,
            pageSize: 1000,
            data: [],
            pagePosition: "bottom",
            pageOptions: {
                layout: ['list', 'sep', 'first', 'prev', 'next', 'last', 'sep', 'refresh', 'sep', 'manual', 'info']
            },
            selection: null,
          
        },
         antrianP: {
            total: 0,
            pageSize: 20,
            data: [],
            pagePosition: "bottom",
            pageOptions: {
                layout: ['list', 'sep', 'first', 'prev', 'next', 'last', 'sep', 'refresh', 'sep', 'manual', 'info']
            },
            selection1: [],
          
        },
         antrianS: {
            total: 0,
            pageSize: 20,
            data: [],
            pagePosition: "bottom",
            pageOptions: {
                layout: ['list', 'sep', 'first', 'prev', 'next', 'last', 'sep', 'refresh', 'sep', 'manual', 'info']
            },
            selection2: null,
          
        },
         antrianO: {
            total: 0,
            pageSize: 20,
            data: [],
            pagePosition: "bottom",
            pageOptions: {
                layout: ['list', 'sep', 'first', 'prev', 'next', 'last', 'sep', 'refresh', 'sep', 'manual', 'info']
            },
            selection3: null,
          
        },
         formReg: {
                field: {
                    ktp: "",
                    nama:"",
                    email:"",
                    tgl_lahir:"",
                    jenis_kelamin:"",
                    tgl_periksa:"",
                    jam_mulai:"",
                    jam_selesai:"",
                    pendaftaranke:"",
                    alamat:"",
                },
                rules: {
                    ktp: ['required'],
                }
            },
        formReschedule: {
            field: {
                id_pendaftaran:"",
                tgl_periksa: "",
                email:"",
            },
            rules: {
                tgl_periksa: ["required"],
            },
        },
        formQR: {
            field: {
              qr_text:"",
            },
            rules: {
              qr_text: ["required"],
            },
          },
        patient_id:"",
        now1:[],
        now2:[],
        now3:[],
        finish1:"",
        finish2:"",
        finish3:"",
        panggil1:"",
        panggil2:"",
        panggil3:"",
        mypdf: null,
        file_data: "",
        qr_text: "",
        redirect: "",
        isProcessing : false,
        ocrText : "",
        pctg : "0.00",
        
       
        
    
    }
      
      this.errorDialog = React.createRef();
      this.successDialog = React.createRef();
      this.pageChange = this.pageChange.bind(this);
      this.pageChange1 = this.pageChange1.bind(this);
      this.scanQR = React.createRef();
      this.formScanQR = React.createRef();
      this.handleChangeFormScanQR = this.handleChangeFormScanQR.bind(this)
      this.text_qr = React.createRef();
      this.onOpen = this.onOpen.bind(this);
      this.qrChange = this.qrChange.bind(this);
      this.scanKTP = React.createRef();
      this.pond = React.createRef();
      this.worker = React.createRef();
      this.updateProgressAndLog = this.updateProgressAndLog.bind(this);
      this.importFile = React.createRef();
      this.importFile2 = React.createRef();
      this.formFile = React.createRef();
      this.handleChangeFile = this.handleChangeFile.bind(this)
      this.fileChange = this.fileChange.bind(this);
      this.text_file = React.createRef();
      this.handlePrintKartu = this.handlePrintKartu.bind(this)
      this.handlePrintScreening = this.handlePrintScreening.bind(this)
      this.selectionChange = this.selectionChange.bind(this);
      this.selectionP = this.selectionP.bind(this);
      this.selectionS = this.selectionS.bind(this);
      this.selectionO = this.selectionO.bind(this);
      this.handleFinish1 = this.handleFinish1.bind(this)
      this.handleFinish2 = this.handleFinish2.bind(this)
      this.handleFinish3 = this.handleFinish3.bind(this)
      this.addReg = React.createRef();
      this.handleChangeFormReg = this.handleChangeFormReg.bind(this)
      this.handleSubmitFormReg = this.handleSubmitFormReg.bind(this);
      this.scanScreening = React.createRef()
      this.scanObservasi = React.createRef()
      this.handleEditClick = this.handleEditClick.bind(this)
      this.editPendaftaran = React.createRef();
      this.handleSubmitCancel = this.handleSubmitCancel.bind(this);
      this.Reschedule = React.createRef();
      this.handleSubmitFormReschedule = this.handleSubmitFormReschedule.bind(this);
      this.handleChangeFormReschedule = this.handleChangeFormReschedule.bind(this);

      this.handleChangeFormQR = this.handleChangeFormQR.bind(this);
      this.handleSubmitFormQR = this.handleSubmitFormQR.bind(this);


    }

    async handleChangeFormQR(name, value) {
        let field = Object.assign({}, this.state.formQR.field);
        field[name] = value;
        await this.setState(
          {
            formQR: {
              ...this.state.formQR,
              field: field,
            },
          },
          () => console.log(this.state.formQR)
        );
      }
      async handleSubmitFormQR() {
        const formData = new FormData();
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
      //  var jam_mulai = d.getHours() + ":" + ("0" + d.getMinutes()).slice(-2);
       // formData.append("jam_mulai", jam_mulai);
        formData.append("patient_id", this.state.formQR.field.qr_text);
        await axios
          .put(uAPI + "/api/v1/qrcode", formData, config)
          .then((response) => {
            console.log(response);
            // this.getData();
            this.scanQR.current.close()
            this.setState({
              formQR: {
                ...this.state.formQR,
                field: {
                  qr_text: "",
                },
              },
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }




    async handleChangeFormReg(name, value) {
        let field = Object.assign({}, this.state.formReg.field);
        field[name] = value;
        await this.setState({
            formReg: {
                ...this.state.formReg,
                field: field
            }
        }, () => console.log(this.state.formReg))
    }
    async handleSubmitFormReg() {
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        
        var d = new Date("1/1/1945 " + this.state.formReg.field.jam_mulai); 
        var jam_mulai = d.getHours() + ':' + ("0" + d.getMinutes()).slice(-2);
        var d_ = new Date("1/1/1945 " + this.state.formReg.field.jam_selesai); 
        var jam_selesai = d_.getHours() + ':' + ("0" + d_.getMinutes()).slice(-2);

        formData.append('ktp', this.state.formReg.field.ktp);
        formData.append('nama', this.state.formReg.field.nama);
         formData.append('email', this.state.formReg.field.email);
          formData.append('tgl_lahir', this.state.formReg.field.tgl_lahir);
           formData.append('jenis_kelamin', this.state.formReg.field.jenis_kelamin);
            formData.append('tgl_periksa', this.state.formReg.field.tgl_periksa);
             formData.append('jam_mulai', jam_mulai);
              formData.append('jam_selesai', jam_selesai);
               formData.append('pendaftaranke', this.state.formReg.field.pendaftaranke);
               formData.append('alamat', this.state.formReg.field.alamat);
            await axios.post(uAPI + '/api/v1/simpanR', formData, config)
                .then(response => {
                    console.log(response)
                    this.getData();
                    this.addReg.current.close();
                    this.setState({
                        formReg: {
                            ...this.state.formReg,
                            field: {
                                ktp: "",
                                nama: "",
                                email: "",
                                tgl_lahir: "",
                                jenis_kelamin: "",
                                tgl_periksa: "",
                                jam_mulai: "",
                                jam_selesai: "",
                                vaksinke: "",
                                alamat: "",

                            },
                        }
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        
    }






   
    async getData() {
        await axios.get(uAPI + '/api/v1/pendaftaran')
            .then(function (response) {
                return response.data.results;
            })
            .then(response => {
                // console.log(response)
                this.setState({
                    pendaftaran: {
                        ...this.state.pendaftaran,
                        data: response,
                        loading: false
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    pageChange(event) {
        console.log(event)
        if (event.refresh) {
            // console.log(event)
            this.setState({
                pendaftaran: {
                    ...this.state.pendaftaran,
                    loading: true,
                    selection: null
                },
            })
            this.getData();
           
        }

    }


    pageChange1(event) {
        console.log(event)
        if (event.refresh) {
            // console.log(event)
            this.setState({
                antrianP: {
                    ...this.state.antrianP,
                    loading: true,
                    selection1: null,
                    panggil1:this.state.antrianP.selection1.antrian_p
                },
            })
            this.getData();
        }

    }

    pageChange2(event) {
        console.log(event)
        if (event.refresh) {
            // console.log(event)
            this.setState({
                antrianS: {
                    ...this.state.antrianS,
                    loading: true,
                    selection2: null,
                    panggil2:this.state.antrianS.selection2.antrian_s
                },
            })
            this.getData();
        }

    }

    pageChange3(event) {
        console.log(event)
        if (event.refresh) {
            // console.log(event)
            this.setState({
                antrianO: {
                    ...this.state.antrianO,
                    loading: true,
                    selection3: null,
                    panggil3:this.state.antrianO.selection3.antrian_o
                },
            })
            this.getData();
        }

    }


     generatePDF = async () => {
       

        var docQr = new jsPDF();

        docQr.setFont("times", "bold");
        docQr.text("QR Kode Booking", 105, 20, null, null, "center");
        docQr.addImage(this.state.qrImage, "PNG", 75, 26, 60, 60);
        docQr.text(this.state.patient_id, 105, 95, null, null, "center");

        docQr.setLineWidth(0.5);
        docQr.line(10, 100, 200, 100);

        docQr.setFontSize(14);
        docQr.setFont("times", "normal");
        docQr.text("1. Pastikan Membawa email/pdf ini (dapat berupa hardcopy) untuk di scan QR code yang tertera ", 10, 107);
        docQr.text("pada saat melakukan pemeriksaan.", 15, 114);
        docQr.text("2. Pastikan datang pada tanggal yang sudah dibooking.", 10, 121);
        docQr.text("3. Pastikan membawa KTP (KTP wali bila belum memiliki KTP).", 10, 128);
        docQr.text("4. Pastikan memakai masker.", 10, 135);
        docQr.text("5. Pastikan didampingi maksimal 1 orang pendamping, terutama untuk pasien anak, manula ", 10, 142);
        docQr.text("ataupun pasien dengan disabilitas.", 15, 148);

        docQr.setLineWidth(0.5);
        docQr.line(10, 152, 200, 152);

        // // doc.save(`Registrasi Pemeriksaan - ${this.state.order_id}.pdf`);

        var QRFile = docQr.output('datauristring');


        await axios.post(uAPI + '/api/v1/mail/nodemailer/myemail', {
            patient_id: this.state.patient_id,
            qr_file: QRFile
        })
            .then(function (response) {
                return response.data;
            })
            .then(response => {
                // console.log(response)
                this.setState({
                    loader: false,
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }





    handleChangeFormScanQR(name, value) {
        console.log(name);
        console.log(value);
        console.log(this.formScanQR.current)
        console.log(this.text_qr.current)
    }

     handleChangeFile(name, value) {
        console.log(name);
        console.log(value);
        console.log(this.formFile.current)
        console.log(this.text_file.current)
    }

    onOpen(e) {
        console.log(e)
    }

    async qrChange(e) {
        // console.log(e)
        await this.setState({
            qr_text: e.target.value,
            loader: true,
        })
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        formData.append('patient_id', this.state.qr_text);

        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }

        if (formData.get('patient_id') !== null || formData.get('patient_id') !== "") {
            await axios.put(uAPI + '/api/v1/qrcode', formData, config)
                .then(response => {
                    if (response.data.status === 406) {
                        this.setState({
                            loader: false,
                            qr_text: "",
                            errorMessage: response.data.message,
                        })
                        this.errorDialog.current.open();
                    } else {
                        this.setState({
                            loader: false,
                            qr_text: "",
                            errorMessage: response.data.message,
                        })
                        this.getData();
                        this.scanQR.current.close()
                        this.successDialog.current.open();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        qr_text: "",
                        errorMessage: "Terjadi Kesalahan. Silahkan dicoba lagi.",
                    })
                    
                })

        }
    }


    
    async handleFinish1(e) {
        // console.log(e)
        await this.setState({
            finish1: e.target.value,
            loader: true,
        })

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
            //const dataP = this.state.now1;
         // {dataP.map(item => (
        formData.append('patient_id', this.state.antrianP.selection1.patient_id);                                 
        formData.append('antrian_p', this.state.finish1);
        
        await axios.put(uAPI + '/api/v1/finish1', formData, config)
                .then(response => {
                    if (response.data.status === 406) {
                        this.setState({
                            loader: false,
                            finish1: "",
                            patient_id:"",
                            errorMessage: response.data.message,
                        })
                        
                    } else {
                        this.setState({
                            loader: false,
                            finish1: "",
                            patient_id:"",
                            errorMessage: response.data.message,
                        })
                        this.getData();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        finish1: "",
                        errorMessage: "Terjadi Kesalahan. Silahkan dicoba lagi.",
                    })
                    
                })


    }

    async handleFinish2(e) {
        // console.log(e)
        await this.setState({
            finish2: e.target.value,
            loader: true,
        })

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        formData.append('antrian_s', this.state.finish2);
        formData.append('patient_id', this.state.antrianS.selection2.patient_id);   
        await axios.put(uAPI + '/api/v1/finish2', formData, config)
                .then(response => {
                    if (response.data.status === 406) {
                        this.setState({
                            loader: false,
                            finish2: "",
                            errorMessage: response.data.message,
                        })
                        
                    } else {
                        this.setState({
                            loader: false,
                            finish2: "",
                            errorMessage: response.data.message,
                        })
                        this.getData();
                       
                        
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        finish2: "",
                        errorMessage: "Terjadi Kesalahan. Silahkan dicoba lagi.",
                    })
                    
                })


    }

    async handleFinish3(e) {
        // console.log(e)
        await this.setState({
            finish3: e.target.value,
            loader: true,
        })

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        formData.append('antrian_o', this.state.finish3);
        await axios.put(uAPI + '/api/v1/finish3', formData, config)
                .then(response => {
                    if (response.data.status === 406) {
                        this.setState({
                            loader: false,
                            finish3: "",
                            errorMessage: response.data.message,
                        })
                        
                    } else {
                        this.setState({
                            loader: false,
                            finish3: "",
                            errorMessage: response.data.message,
                        })
                        this.getData();
                        
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        finish2: "",
                        errorMessage: "Terjadi Kesalahan. Silahkan dicoba lagi.",
                    })
                    
                })


    }



    papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    };


     //::::::::: for putting all data in CSV to MySQL :::::::::::
    handleForce = async (data, fileInfo) => {
    await  this.setState({ loading: true });

    let d = JSON.stringify(data,null,4);


    console.log('dataok',d);

    fetch(uAPI + '/api/v1/import', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: d,
    })
      .then((response) => {
        this.setState({ loading: false });

        if (response.ok) {
         //   this.generatePDF();
            this.getData();
            this.importFile.current.close();
            this.successDialog.current.open();
          return response.json();
            
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((responseJson) => {
      //   this.generatePDF();
        console.log(responseJson);

      })
      .catch((error) => {
        this.setState({ error: true });
        console.log(error, this.state);
      });
    };


    handleForce2 = async (data, fileInfo) => {
    await  this.setState({ loading: true });

    let d = JSON.stringify(data,null,4);


    console.log('dataok',d);

    fetch(uAPI + '/api/v1/import2', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: d,
    })
      .then((response) => {
        this.setState({ loading: false });

        if (response.ok) {
         //   this.generatePDF();
            this.getData();
            this.importFile2.current.close();
            this.errorDialog.current.open();
          return response.json();
            
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((responseJson) => {
      //   this.generatePDF();
        console.log(responseJson);

      })
      .catch((error) => {
        this.setState({ error: true });
        console.log(error, this.state);
      });
    };
    //:::::::::::::::: End of POST call to save data ::::::::::::::

    async fileChange(e) {
        console.log(e)
        await this.setState({
            file_data: e.target.files[0],
            loader: true,
        })
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        formData.append('file_data', this.state.file_data);

       
        await axios.post(uAPI + '/api/v1/import', formData, config)
            .then(response => {
                if (response.data.status === 406) {
                    this.setState({
                        loader: false,
                        file_data:this.state.file_data,
                        errorMessage: response.data.message,
                    })
                    this.errorDialog.current.open();
                } else {
                    this.setState({
                        loader: false,
                        file_data: this.state.file_data,
                        errorMessage: response.data.message,
                    })
                    this.getData();
                    this.importFile.current.close()
                    this.errorDialog.current.open();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loader: false,
                    file_data: "",
                    errorMessage: "Terjadi Kesalahan. Silahkan dicoba lagi.",
                })
                
            })

    }

    async doOCR(file) {
        this.setState({
            isProcessing : true,
            ocrText : '',
            pctg : '0.00'
        })
        // Loading tesseract.js functions
        await this.worker.load();
        // Loadingg language as 'English'
        await this.worker.loadLanguage('ind');
        await this.worker.initialize('ind');
        // Sending the File Object into the Recognize function to
        // parse the data
        const { data: { text } } = await this.worker.recognize(file.file);
        this.setState({
            isProcessing : false,
            ocrText : text
        })
    };
    updateProgressAndLog(m){

        // Maximum value out of which percentage needs to be
        // calculated. In our case it's 0 for 0 % and 1 for Max 100%
        // DECIMAL_COUNT specifies no of floating decimal points in our
        // Percentage
        var MAX_PARCENTAGE = 1 ;
        var DECIMAL_COUNT = 2 ;

        if(m.status === "recognizing text"){
            var pctg = (m.progress / MAX_PARCENTAGE) * 100
            this.setState({
                pctg : pctg.toFixed(DECIMAL_COUNT)
            })

        }
    }


     async selectionChange(event) {
        // console.log(event)
        await this.setState({
            pendaftaran: {
                ...this.state.pendaftaran,
                selection: event
            },
        }, () => console.log(this.state.pendaftaran.selection))
        
    }

    async selectionP(event) {
      //   console.log(event)
        await this.setState({
            antrianP: {
                ...this.state.antrianP,
                selection1: event,
                
            },
            panggil1:event.antrian_p,
        }, () => console.log(this.state.panggil1))
        
    }


    async selectionS(event) {
      //   console.log(event)
        await this.setState({
            antrianS: {
                ...this.state.antrianS,
                selection2: event,
                
            },
            panggil2:event.antrian_s,
        }, () => console.log(this.state.panggil2))
        
    }


    async selectionO(event) {
      //   console.log(event)
        await this.setState({
            antrianO: {
                ...this.state.antrianO,
                selection3: event,
                
            },
            panggil3:event.antrian_o,
        }, () => console.log(this.state.panggil3))
        
    }


    async handlePrintKartu() {
        if (this.state.pendaftaran.selection) {
             window.open("/print-kartu?pid="+this.state.pendaftaran.selection.patient_id, "_blank")
           
        } else {
            this.setState({
                errorMessage: "Tidak ada data pendaftaran yang Dipilih",
            })
            this.errorDialog.current.open();
        }
    }

    async handlePrintScreening() {
        if (this.state.pendaftaran.selection) {
             window.open("/print-screening?pid="+this.state.pendaftaran.selection.patient_id, "_blank")
          
        } else {
            this.setState({
                errorMessage: "Tidak ada data pendaftaran yang Dipilih",
            })
            this.errorDialog.current.open();
        }
    }

    async handleEditClick() {
        if (this.state.pendaftaran.selection) {
            let selection = this.state.pendaftaran.selection;
            console.log('select',selection)
            await this.setState({
                pendaftaran: {
                    id_pendaftaran: selection.id_pendaftaran,
                    tgl_periksa: selection.tgl_periksa,
                    nama:selection.nama
                },
            }, () => console.log("edit click", this.state.pendaftaran.selection))
            this.editPendaftaran.current.open();
            this.getData();
        } else {
            // console.log('tidak ada yang di select');
            this.setState({
          //      errorMessage: "Tidak Ada Data yang Dipilih , mohon klik data sebelum klik edit , Terima kasih",
            })
            this.errorDialog.current.open();
        }
    }

    async handleSubmitCancel() {
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        formData.append('id_pendaftaran', this.state.pendaftaran.selection.id_pendaftaran);
        formData.append('patient_id', this.state.pendaftaran.selection.patient_id);
        formData.append('status', '3');
        

        if (formData.get('patient_id') !== "") {
            await axios.put(uAPI + '/api/v1/cancel', formData, config)
                .then(response => {
                    // console.log(response)
                    this.getData();
                    this.setState({
                        status:""
                    })
                  
                   
                })
                .catch(function (error) {
                    console.log(error);
                });
                
        }
        else{
        
        this.getData();
        }
    }

    async handleSubmitFormReschedule() {
        const formData = new FormData();
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        // var d = new Date("1/1/1945 " + this.state.formReminder.field.hari);
        // var hari = d.getHours() + ":" + ("0" + d.getMinutes()).slice(-2);
        // formData.append("hari", jam_mulai);
        formData.append("id_pendaftaran", this.state.pendaftaran.selection.id_pendaftaran);
        formData.append("email", this.state.pendaftaran.selection.email);
        formData.append("patient_id", this.state.pendaftaran.selection.patient_id);
        formData.append("ktp", this.state.pendaftaran.selection.ktp);
        formData.append("tgl_periksa", this.state.formReschedule.field.tgl_periksa);
        formData.append("branch_name", this.state.pendaftaran.selection.branch_name);
        formData.append("nama", this.state.pendaftaran.selection.nama);
        await axios
          .put(uAPI + "/api/v1/reschedule", formData, config)
          .then((response) => {
            console.log(response);
            this.getData();
            this.Reschedule.current.close();
            this.setState({
              formReschedule: {
                ...this.state.formReschedule,
                field: {
                  tgl_periksa: "",
                },
              },
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      async handleChangeFormReschedule(name, value) {
        let field = Object.assign({}, this.state.formReschedule.field);
        field[name] = value;
        await this.setState(
          {
            formReschedule: {
              ...this.state.formReschedule,
              field: field,
            },
          },
          () => console.log(this.state.formReschedule)
        );
      }

    async componentDidMount(){
        
        this.getData();

         try {
            setInterval(async () => {
                this.getData();
                
            }, 9000 * 200);
          } catch(e) {
            console.log(e);
          }

        
        await QRCode.toDataURL(this.state.pendaftaran.data, { type: "png" })
            .then(img => {
                this.setState({
                    qrImage: img
                })
            })
        this.worker = createWorker({
            logger: m => this.updateProgressAndLog(m),
        });

    }

    render(){
        const data= this.state.now1;
        const data2= this.state.now2;
        const data3= this.state.now3;

        function capture(imgSrc) {
        console.log(imgSrc);
        

        }

        $(":input").keypress(function(event){
            if (event.which == '10' || event.which == '13') {
                event.preventDefault();
            }
        });
       

        return(  
            <> 
           <Col md={12} style={{ marginLeft: '30px'}}>
                <Layout style={{ width: '100%', height: '800px' }}>
                       <LayoutPanel region="center" title="Data MCU" style={{ height: '100%' }}>
                      
                        <Panel bodyStyle={{ padding: '0px' }}>
                        { this.state.detail_login.level == '1' ? 
                             <>
                            <LinkButton iconCls="icon-save" plain onClick={() => this.importFile.current.open()}>Import Excel</LinkButton>
                            <LinkButton iconCls="icon-search" plain onClick={() => this.scanQR.current.open()}>Scan QR</LinkButton>
                            </>
                            : null }
                        </Panel>

                       
                        
                        <DataGrid style={{ backgroundColor: '#f2c8c8',height:'100%',width:2000}}   {...this.state.pendaftaran} selectionMode='single' selection={this.state.pendaftaran.selection} onSelectionChange={this.selectionChange} columnResizing filterable virtualScroll columnMoving>
                          <GridColumn field="rn" align="center" width='30px' 
                                cellCss="datagrid-td-rownumber" filterable={false}
                                render={({ rowIndex }) => (
                                    <span>{rowIndex + 1}</span>
                                )}
                               
                            />
                          <GridColumn width="150px" title="Action" align="center" filterable={false}  render={({ row }) => (  
                          <center>
                          
                            <LinkButton style={{textAlign: 'center', whiteSpace: 'normal', backgroundColor: 'rgba(201, 217, 221, 0.8)', color: 'white', padding: '4px', border: '1px solid currentColor', borderRadius: '2000px'}}  plain onClick={this.handleSubmitCancel}>Batal</LinkButton>
                            <LinkButton style={{textAlign: 'center', whiteSpace: 'normal', backgroundColor: 'rgba(201, 217, 221, 0.8)', color: 'white', padding: '4px', border: '1px solid currentColor', borderRadius: '2000px'}}  plain onClick={() => this.Reschedule.current.open()}>Reschedule</LinkButton>

                        </center>   
                          )}  />
                          <GridColumn field="status" title="Status" align="center" sortable width='120px'
                           render={({ row }) => (
                            <div style={{ padding: "4px 0" }}>
                              <div style={{ fontWeight: "bold" }}>

                              {
                                row.status == '1'
                                ? 
                                'Sudah Reservasi'
                                :
                                row.status == '2'
                                ?
                                'Sudah Konfirmasi'
                                :
                                row.status == '3'
                                ?
                                'Cancel'
                                :
                                ''

                            }
                                
                                </div>
                            </div>
                          )}
                          
                          />
                          <GridColumn field="branch_name" title="Cabang" align="center" sortable width='250px'
                           render={({ row }) => (
                            <div style={{ padding: "4px 0" }}>
                              <div style={{ fontWeight: "bold" }}>{row.branch_name}</div>
                              {/* <Clipboard data-clipboard-text={row.ktp}>copy</Clipboard> */}
                            </div>
                          )}
                          
                          />
                          <GridColumn field="hubungan" title="Hubungan" align="center" sortable width="150px"></GridColumn>
                          <GridColumn field="patient_id" title="PID" align="center" sortable width='130px' 
                           render={({ row }) => (
                                      <div style={{ padding: '4px 0' }}>
                                        <div style={{ fontWeight: 'bold' }}>{row.patient_id}</div>
                                          <Clipboard data-clipboard-text={row.patient_id}>
                                            copy
                                          </Clipboard>   
                                      </div>
                            )}

                          />

                  
                          <GridColumn field="ktp" title="KTP" align="center" sortable width='50px'

                          render={({ row }) => (
                                      <div style={{ padding: '4px 0' }}>
                                        <div style={{ fontWeight: 'bold' }}>{row.ktp}</div>
                                          <Clipboard data-clipboard-text={row.ktp}>
                                            copy
                                          </Clipboard>   
                                      </div>
                            )}

                          />
                          <GridColumn field="nama" title="Nama Lengkap" align="left" sortable width='200px' 
                           render={({ row }) => (
                                      <div style={{ padding: '4px 0' }}>
                                        <div style={{ fontWeight: 'bold' }}>{row.nama}</div>
                                          <Clipboard data-clipboard-text={row.nama}>
                                            copy
                                          </Clipboard>   
                                      </div>
                            )}
                          />
                          <GridColumn field="check_date" title="Tgl Periksa" align="center" sortable width="150px"></GridColumn>
                          <GridColumn field="hadir" title="Kehadiran" align="center" width='170px' sortable></GridColumn>
                          <GridColumn field="alamat" title="Alamat" align="left" sortable width='250px'></GridColumn>
                          <GridColumn field="email" title="Email" align="left" sortable width="170px"></GridColumn>
                          <GridColumn field="birth_date" title="Tgl Lahir" align="center" sortable width="100px"></GridColumn>
                          <GridColumn field="umur" title="Umur" align="center" sortable width='70px'></GridColumn>
                          <GridColumn field="jenis_kelamin" title="Jenis Kelamin" align="center" width='100px' sortable></GridColumn>
                          
                        </DataGrid>
                         
                         </LayoutPanel>
                  </Layout>
            </Col>

            

            <Dialog title="Tambah Pasien"
                    style={{ width: '400px', height: '400px', top: '30%' }}
                    modal
                    closed={true}
                    draggable
                    ref={this.addReg}
                        >
                    <div className='pd-20'>
                        <h3 className='text-center'>Tambah Pasien Vaksin</h3>

                                <Form
                                        ref={this.formReg}
                                        style={{ maxWidth: 500 }}
                                        model={this.state.formReg.field}
                                        rules={this.state.formReg.rules}
                                        labelWidth={120}
                                        // labelAlign="right"
                                        labelPosition="top"
                                        //floatingLabel
                                        // validateRules={this.getCustomRules}
                                        onChange={this.handleChangeFormReg}
                                    >
                                        
                                        <FormField name="ktp" label="Ktp:">
                                            <TextBox value={this.state.formReg.field.ktp} ></TextBox>
                                        </FormField>

                                         <FormField name="nama" label="Nama:">
                                            <TextBox value={this.state.formReg.field.nama} ></TextBox>
                                        </FormField>

                                        <FormField name="jenis_kelamin" label="Jenis Kelamin:">
                                            <TextBox value={this.state.formReg.field.jenis_kelamin} ></TextBox>
                                        </FormField>

                                        <FormField name="alamat" label="Alamat:">
                                            <TextBox value={this.state.formReg.field.alamat} ></TextBox>
                                        </FormField>

                                         <FormField name="email" label="Email:">
                                            <TextBox value={this.state.formReg.field.email} ></TextBox>
                                        </FormField>

                                         <FormField name="tgl_lahir" label="Tgl Lahir:">
                                            <DateBox 
                                                  panelStyle={{width:250,height:300}}
                                                  value={this.state.formReg.field.tgl_lahir}
                                                />
                                        </FormField>

                                        <FormField name="tgl_periksa" label="Tgl Periksa:">
                                            <DateBox 
                                                  panelStyle={{width:250,height:300}}
                                                  value={this.state.formReg.field.tgl_periksa}
                                                />
                                        </FormField>
                                         <FormField name="pendaftaranke" label="Pendaftaran ke:">
                                            <TextBox value={this.state.formReg.field.pendaftaranke} ></TextBox>
                                        </FormField>

                                        <FormField >
                                            <LinkButton onClick={this.handleSubmitFormReg} >Submit</LinkButton>
                                        </FormField>
                                </Form>


                    </div>
                </Dialog>

            <Dialog
                title="Alert"
                style={{ width: '300px', height: '120px' }}
                modal
                closed={true}
                ref={this.errorDialog}>
                <p style={{ textAlign: 'center', margin: '10px', fontSize: '16px' }}>Gagal disimpan</p>
            </Dialog>

            <Dialog
                title="Alert"
                style={{ width: '300px', height: '150px',backgroundColor:'red',color:'red' }}
                modal
                closed={true}
                ref={this.successDialog}>
                <p style={{ textAlign: 'center', margin: '10px', fontSize: '16px' }}>Berhasil disimpan</p>
            </Dialog>

            <Dialog title="Edit"
            style={{ width: '500px', height: '400px' }}
            modal
            closed={true}
            ref={this.editPendaftaran}>
            <br /> <br /> 
            <div className='pd-20'>
            <h3 className="text-center">Edit Pendaftaran</h3>
            <br />
            <form onSubmit={e => this.onSubmit(e)}>
            
            <div className="row row-sm form-group" style={{marginRight: '25px',marginLeft: '25px'}}>
            <div className="col-md-6 col-lg-6">
            <label className="form-control-label">Nama Lengkap : <span className="tx-danger">*</span></label>
            <input className="form-control" name="nama" type="text" value={this.state.pendaftaran.nama} onChange={this.onChange} style={{backgroundColor:'black',color:'white'}} readOnly/>
            </div>
            <div className="col-md-6 col-lg-6">
            <label className="form-control-label">Tgl Periksa : <span className="tx-danger">*</span></label>
            <input className="form-control" name="tgl_periksa" type="text" value={this.state.pendaftaran.tgl_periksa} onChange={this.onChange}/>
            </div>
            </div>

            </form>
            </div>
            </Dialog>

            <Dialog title="Import Data"
                    style={{ width: '600px', height: '240px', top: '30%' }}
                    modal
                    closed={true}
                    draggable
                    ref={this.importFile}
                        >
                    <div className='pd-20'>
                        <h3 className='text-center'>Data Perusahaan</h3>

                        <form >

                            

                            <div className="form-group">
                                <label>Silahkan upload data :</label>
                                <CSVReader
                                cssClass="react-csv-input"
                                label="Select CSV  :"
                                onFileLoaded={this.handleForce.bind(this)}
                                parserOptions={this.papaparseOptions}
                                />
                                
                            </div>
                            {
                                this.state.loader ? (
                                    <div className="text-center">
                                        <Loader type="TailSpin" color="#00BFFF" height={40} width={100} />
                                    </div>
                                ) : null
                            }
                         </form>

                    </div>
                </Dialog>


                 <Dialog title="Import Data"
                    style={{ width: '600px', height: '240px', top: '30%' }}
                    modal
                    closed={true}
                    draggable
                    ref={this.importFile2}
                        >
                    <div className='pd-20'>
                        <h3 className='text-center'>Data Perusahaan</h3>

                        <form >

                            

                            <div className="form-group">
                                <label>Silahkan upload data :</label>
                                <CSVReader
                                cssClass="react-csv-input"
                                label="Select CSV  :"
                                onFileLoaded={this.handleForce2.bind(this)}
                                parserOptions={this.papaparseOptions}
                                />
                                
                            </div>
                            {
                                this.state.loader ? (
                                    <div className="text-center">
                                        <Loader type="TailSpin" color="#00BFFF" height={40} width={100} />
                                    </div>
                                ) : null
                            }
                        </form>

                    </div>
                </Dialog>


            {/* <Dialog title="Scan QR"
                    style={{ width: '600px', height: '240px', top: '30%' }}
                    modal
                    closed={true}
                    draggable
                    ref={this.scanQR}
                        >
                    <div className='pd-20'>
                        <h3 className='text-center'>Verifikasi Kedatangan pendaftaran Hari Ini</h3>

                        <form >
                            <div className="form-group">
                                <label>Silahkan scan QR Code / PID di kolom bawah ini :</label>
                                <input type="text" className="form-control" name="qr_text" value={this.state.qr_text} autoFocus={true} onChange={this.qrChange} readOnly={this.state.loader ? true : false} required />
                            </div>
                            {
                                this.state.loader ? (
                                    <div className="text-center">
                                        <Loader type="TailSpin" color="#00BFFF" height={40} width={100} />
                                    </div>
                                ) : null
                            }
                        </form>

                    </div>
                </Dialog> */}


                <Dialog title="Scan QR"
                    style={{ width: '600px', height: '240px', top: '30%' }}
                    modal
                    closed={true}
                    draggable
                    ref={this.scanQR}
                        >
                    <div className='pd-20'>
                        <h3 className='text-center'>Verifikasi Kedatangan pendaftaran Hari Ini</h3>
                                <Form
                                ref={this.formQR}
                                style={{ maxWidth: 500 }}
                                model={this.state.formQR.field}
                                rules={this.state.formQR.rules}
                                labelWidth={120}
                                // labelAlign="right"
                                labelPosition="top"
                                //floatingLabel
                                // validateRules={this.getCustomRules}
                                onChange={this.handleChangeFormQR}
                                >

                                <FormField name="qr_text" label="Scan QR:">
                                <TextBox value={this.state.formQR.field.qr_text}></TextBox>
                                </FormField>

                                <FormField>
                                <LinkButton onClick={this.handleSubmitFormQR}>
                                    Simpan
                                </LinkButton>
                                </FormField>
                                </Form>

                    </div>
                </Dialog>


             

                <Dialog
                title="Reschedule"
                style={{ width: "400px", height: "400px", top: "30%" }}
                modal
                closed={true}
                draggable
                ref={this.Reschedule}>
                <div className="pd-20">
                    <h3 className="text-center">Reschedule</h3>
                    <Form
                    ref={this.formReschedule}
                    style={{ maxWidth: 500 }}
                    model={this.state.formReschedule.field}
                    rules={this.state.formReschedule.rules}
                    labelWidth={120}
                    // labelAlign="right"
                    labelPosition="top"
                    //floatingLabel
                    // validateRules={this.getCustomRules}
                    onChange={this.handleChangeFormReschedule}>
                    
                    <FormField name="tgl_periksa" label="Tgl Periksa:">
                        <p>format yyyy-mm-dd</p>
                    <TextBox
                        value={this.state.formReschedule.tgl_periksa}
                        ></TextBox>
                    </FormField>

                    <FormField>
                        <LinkButton onClick={this.handleSubmitFormReschedule}>
                        Submit
                        </LinkButton>
                    </FormField>
                    </Form>
                </div>
                </Dialog>
             
              </>
        )
    }
    }
    export default Pendaftaran;