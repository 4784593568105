import React from 'react';
import {
    Switch,
    Route,
    withRouter,
    useRouteMatch,
    useParams
} from "react-router-dom";
import Pendaftaran from './Pendaftaran';
import Kuota from './Kuota';
import Reminder from './Reminder';

function PostType(props) {
    let match = useRouteMatch();
    let { postType } = useParams();
    if (postType === 'pendaftaran') {
        return (
            <Switch>
                <Route path={match.path}>
                    <Pendaftaran detail_login={props.detail_login} />
                </Route>
            </Switch>
        );
    }else if (postType === 'kuota') {
        return (
            <Switch>
                <Route path={match.path}>
                    <Kuota detail_login={props.detail_login} />
                </Route>
            </Switch>
        );
    }  else if (postType === 'reminder') {
        return (
            <Switch>
                <Route path={match.path}>
                    <Reminder detail_login={props.detail_login} />
                </Route>
            </Switch>
        );
    }  
    else {
        return <h3>Not Found</h3>;
    }
}

export default withRouter(PostType);