import React, { Component } from "react";
import axios from 'axios';
import { uAPI } from './config/Config';
import queryString from 'query-string';


class Mobile extends Component {
constructor(props) {
super(props);
this.state = {
        tv1 :[],
        tv2 :[],
        tv3 :[],
        now1:[],
        now2:[],
        now3:[],
        antrian1: [],
        antrian2: [],
        antrian3: [],
        query: queryString.parse(this.props.location.search)
       
        }

}



    async getTv1() {
        await axios.get(uAPI + '/api/v1/antrianTv1')
         .then(function (response) {
              
                return response.data.results;
                
            })
            .then(results => {
                 console.log(results); 
                this.setState({
                   tv1: results
                })
                
            })
            .catch(function (error) {
                console.log(error);
            });
           
    }

     async getTv2() {
        await axios.get(uAPI + '/api/v1/antrianTv2')
         .then(function (response) {
              
                return response.data.results;
                
            })
            .then(results => {
                 console.log(results); 
                this.setState({
                   tv2: results
                })
                
            })
            .catch(function (error) {
                console.log(error);
            });
           
    }


      async getTv3() {
        await axios.get(uAPI + '/api/v1/antrianTv3')
         .then(function (response) {
              
                return response.data.results;
                
            })
            .then(results => {
                 console.log(results); 
                this.setState({
                   tv3: results
                })
                
            })
            .catch(function (error) {
                console.log(error);
            });
           
    }

    

    async getNow1() {
        await axios.get(uAPI + '/api/v1/nowP')
         .then(function (response) {
              
                return response.data.results;
                
            })
            .then(results => {
                 console.log(results); 
                this.setState({
                   now1: results
                })
                
            })
            .catch(function (error) {
                console.log(error);
            });
           
    }

    async getNow2() {
        await axios.get(uAPI + '/api/v1/nowS')
         .then(function (response) {
              
                return response.data.results;
                
            })
            .then(results => {
                 console.log(results); 
                this.setState({
                   now2: results
                })
                
            })
            .catch(function (error) {
                console.log(error);
            });
           
    }

    async getNow3() {
        await axios.get(uAPI + '/api/v1/nowO')
         .then(function (response) {
              
                return response.data.results;
                
            })
            .then(results => {
                 console.log(results); 
                this.setState({
                   now3: results
                })
                
            })
            .catch(function (error) {
                console.log(error);
            });
           
    }





async componentDidMount(){

      console.log('pid',this.state.query) 
      if (this.state.query.pid) {
         const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            formData.append('patient_id', this.state.query.pid);

            await axios.post(uAPI + '/api/v1/antrian1', formData, config)
                .then(function (response) {
                    return response.data.results;
                })
                .then(results => {
                    console.log(results);   
                    // <Informed data={results} />
                    this.setState({
                        antrian1: results,
                        //redirect: "/print-kartu"
                    }, () => console.log('antrian1',this.state.antrian1))
                })
                .catch(function (error) {
                    console.log(error);
                });
          }


          if (this.state.query.pid) {
         const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            formData.append('patient_id', this.state.query.pid);

            await axios.post(uAPI + '/api/v1/antrian2', formData, config)
                .then(function (response) {
                    return response.data.results;
                })
                .then(results => {
                    console.log(results);   
                    // <Informed data={results} />
                    this.setState({
                        antrian2: results,
                        //redirect: "/print-kartu"
                    }, () => console.log('antrian2',this.state.antrian2))
                })
                .catch(function (error) {
                    console.log(error);
                });
          }

          if (this.state.query.pid) {
         const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            formData.append('patient_id', this.state.query.pid);

            await axios.post(uAPI + '/api/v1/antrian3', formData, config)
                .then(function (response) {
                    return response.data.results;
                })
                .then(results => {
                    console.log(results);   
                    // <Informed data={results} />
                    this.setState({
                        antrian3: results,
                        //redirect: "/print-kartu"
                    }, () => console.log('antrian3',this.state.antrian3))
                })
                .catch(function (error) {
                    console.log(error);
                });
          }



        this.getTv1();
        this.getTv2();
        this.getTv3();
        this.getNow1();
        this.getNow2();
        this.getNow3();

         try {
            setInterval(async () => {
              console.log('pid',this.state.query)

        if (this.state.query.pid) {
         const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            formData.append('patient_id', this.state.query.pid);

            await axios.post(uAPI + '/api/v1/antrian1', formData, config)
                .then(function (response) {
                    return response.data.results;
                })
                .then(results => {
                    console.log(results);   
                    // <Informed data={results} />
                    this.setState({
                        antrian1: results,
                        //redirect: "/print-kartu"
                    }, () => console.log('antrian1',this.state.antrian1))
                })
                .catch(function (error) {
                    console.log(error);
                });
          }


          if (this.state.query.pid) {
         const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            formData.append('patient_id', this.state.query.pid);

            await axios.post(uAPI + '/api/v1/antrian2', formData, config)
                .then(function (response) {
                    return response.data.results;
                })
                .then(results => {
                    console.log(results);   
                    // <Informed data={results} />
                    this.setState({
                        antrian2: results,
                        //redirect: "/print-kartu"
                    }, () => console.log('antrian2',this.state.antrian2))
                })
                .catch(function (error) {
                    console.log(error);
                });
          }

          if (this.state.query.pid) {
         const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            formData.append('patient_id', this.state.query.pid);

            await axios.post(uAPI + '/api/v1/antrian3', formData, config)
                .then(function (response) {
                    return response.data.results;
                })
                .then(results => {
                    console.log(results);   
                    // <Informed data={results} />
                    this.setState({
                        antrian3: results,
                        //redirect: "/print-kartu"
                    }, () => console.log('antrian3',this.state.antrian3))
                })
                .catch(function (error) {
                    console.log(error);
                });
          }




         
                this.getTv1();
                this.getTv2();
                this.getTv3();
                this.getNow1();
                this.getNow2();
                this.getNow3();
            }, 5000);
          } catch(e) {
            console.log(e);
          }


}



render() {
   const now1 = this.state.now1;
   const now2 = this.state.now2;
   const now3 = this.state.now3;
   const tv1 = this.state.tv1;
   const tv2 = this.state.tv2;
   const tv3 = this.state.tv3;
   const antrian1 = this.state.antrian1;
   const antrian2 = this.state.antrian2;
   const antrian3 = this.state.antrian3;

   
  
return (

  <div>
  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n   html,\nbody {\n  height: 100%;\n  width: 100%;\n}\n\n.footer {\n  background-color: #f5f5f5;\n}\n\niframe {\n  width: 100%;\n  height: 100%;\n}\n\n.marquee,\n.marquee-with-options,\n.marquee-vert-before,\n.marquee-vert {\n  width: 100%;\n  overflow: hidden;\n}\n\n.card .card-header {\n  font-size: 2rem;\n}\n\n.queue-number {\n  font-size: 4.8vh;\n}\n\n.queue-number-sm {\n  font-size: 3vh;\n}\n\n.list-group-item.active {\n  background-color: #00bc8c;\n  color: white;\n  border-color: #00bc8c;\n}\n\n.box {\n  min-height: 48vh;\n}\n\n.box-head {\n  width: 100%;\n  height: 100%;\n  /* min-height: 6vh; */\n  background: #444;\n  border-radius: 0.5vh;\n\n}\n\n.box-detail {\n  font-size: 2.5vh;\n  position: absolute;\n  top: 3.5vh;\n  left: 50%;\n  text-align: center;\n  -webkit-transform: translate(-50%, -50%);\n  transform: translate(-50%, -50%);\n}\n  " }} />

  <main role="main" className="flex-shrink-0" style={{height: '95%',backgroundColor:'#28a745'}}>
    <div className="rowFluid" style={{height: '100%', width: '100%'}}>
      
      <div className="">
        <div className="alert alert-dismissible alert-success" id="alertErrorMqtt">
          <h2 style={{textAlign:'center'}}>Queue System</h2>
        </div>
        <span className="mt-2 btn btn-secondary btn-lg btn-block" id="txtServicePointName" style={{backgroundColor:'white'}}>
          <img src="https://biomedika.co.id/images/web/logo-biomedika.jpg" width={300} height={59} /> 
        </span>
        <br/>
         <div className="alert alert-dismissible alert-success" id="alertErrorMqtt">
          <h2 style={{textAlign:'center'}}>Nomor antrian saat ini:</h2>
          {antrian1 && antrian1.map(item => (
          <h2 style={{textAlign:'center'}}>{item.antrian_p}</h2>
            ))}

            {antrian2 && antrian2.map(item => (
          <h2 style={{textAlign:'center'}}>{item.antrian_s}</h2>
            ))}

            {antrian3 && antrian3.map(item => (
          <h2 style={{textAlign:'center'}}>{item.antrian_o}</h2>
            ))}
        </div>
        <div className="card text-white bg-warning mb-2 mt-2" style={{height: '200px'}}>
          <div className="card-header">
            <div className="row">
              <div className="col-md-8">Pendaftaran</div>
             
            </div>
          </div>
          <div className="card-body">
            <div id="currentList">
              <div className="row">
              
                <span>
               {now1 && now1.map(item => (
               <span style={{marginLeft:'20px',fontSize:'30px'}}>{item.antrian_p}</span>
                ))}

                {tv1 && tv1.map(item => (
                <span style={{marginLeft:'20px',fontSize:'20px'}}>{item.antrian_p}</span>
                ))}

                </span>
                
              </div>



            </div>
          </div>
        </div>
        <div className="card text-white bg-danger mb-2 mt-2" style={{height: '200px'}}>
          <div className="card-header">
            <div className="row">
              <div className="col-md-8">Screening</div>
              <div className="col-md-4">Daftar Antrian
              </div>
            </div>
          </div>
          <div className="card-body">
            <div id="currentList">
                <div className="row">
              

                 <span>
               {now2 && now2.map(item => (
               <span style={{marginLeft:'20px',fontSize:'30px'}}>{item.antrian_s}</span>
                ))}

                {tv2 && tv2.map(item => (
                <span style={{marginLeft:'20px',fontSize:'20px'}}>{item.antrian_s}</span>
                ))}

                </span>
              
              </div>
            </div>
          </div>
        </div>
        <div className="card text-white bg-success mb-2 mt-2" style={{height: '200px'}}>
          <div className="card-header">
            <div className="row">
              <div className="col-md-8">Observasi</div>
              <div className="col-md-4">Daftar Antrian</div>
            </div>
          </div>
          <div className="card-body">
            <div id="nextQueueList">
             <div className="row">
               
                 <span>
               {now3 && now3.map(item => (
               <span style={{marginLeft:'20px',fontSize:'30px'}}>{item.antrian_o}</span>
                ))}

                {tv3 && tv3.map(item => (
                <span style={{marginLeft:'20px',fontSize:'20px'}}>{item.antrian_o}</span>
                ))}

                </span>

                </div>
            </div>
          </div>
        </div>
        <div className="card text-white bg-success mb-2 mt-2" style={{height: '100px'}}>
          <div className="card-header">
            <div className="row">
              <div className="col-md-12"><marquee> Terima kasih telah mempercayai Bio Medika</marquee></div>
            </div>
          </div>
          <div className="card-body">
            <div id="nextQueueList" />
          </div>
        </div>
      </div>
    </div>
  </main>
  <footer className="footer mt-auto py-3 bg-success text-white">
    <div style={{width: '100%'}} className="marquee"  data-direction="left">
      <span className="font-weight-bolder h4">
        <marquee>COPYRIGHT 2021 . PT. BIO MEDIKA - LABORATORIUM KLINIK UTAMA</marquee>
      </span>
    </div>
  </footer>
  {/* libs */}
  {/* app */}
  {/**/}
</div>



)
}
}
export default Mobile;
