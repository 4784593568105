import React, { Component, Fragment } from "react";
import {
  DataGrid,
  Dialog,
  Form,
  FormField,
  GridColumn,
  Layout,
  LayoutPanel,
  LinkButton,
  Panel,
  TextBox,
  TimePicker,
} from "rc-easyui";
import { Col } from "react-bootstrap";
import "./index.scss";
import { uAPI } from "../config/Config";
import axios from "axios";
import { createWorker } from "tesseract.js";
import "filepond/dist/filepond.min.css";
import moment from "moment";
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const customStyles = {
  option: (provided, state) => ({
    ...provided,
   
    color: state.isSelected ? 'red' : 'black',
  
  }),
  
}

function getAge(DOB) {
  var today = new Date();
  var birthDate = new Date(DOB);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1;
  }

  return age;
}

class Kuota extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: this.props.login,
      detail_login: this.props.detail_login,
      loading: false,
      error: false,
      option_cabang: [],
      branch_id:"",
      kuota: {
        total: 0,
        pageSize: 100,
        data: [],
        pagePosition: "bottom",
        pageOptions: {
          layout: [
            "list",
            "sep",
            "first",
            "prev",
            "next",
            "last",
            "sep",
            "refresh",
            "sep",
            "manual",
            "info",
          ],
        },
        selection: null,
      },
      formKuota: {
        field: {
          hari:"",
          kuota: "",
        },
        rules: {
          kuota: ["required"],
          hari: ["required"],
        },
      },
      editKuota: {
        field: {
          hari:"",
          kuota: "",
          branch_id:"",
          branch_name:"",
        },
        rules: {
          kuota: ["required"],
          hari: ["required"],
        },
      },
      patient_id: "",
    };

    this.selectionChange = this.selectionChange.bind(this);
    this.addKuota = React.createRef();
    this.updateKuota = React.createRef();
    this.handleChangeFormKuota = this.handleChangeFormKuota.bind(this);
    this.handleSubmitFormKuota = this.handleSubmitFormKuota.bind(this);
    this.handleConvertHours = this.handleConvertHours.bind(this);
    this.handleChangeEditKuota = this.handleChangeEditKuota.bind(this);
    this.handleSubmitEditKuota = this.handleSubmitEditKuota.bind(this);
    this.getData_ = this.getData_.bind(this);
    this.deleteData = this.deleteData.bind(this);
  }

  async handleChangeFormKuota(name, value) {
    let field = Object.assign({}, this.state.formKuota.field);
    field[name] = value;
    await this.setState(
      {
        formKuota: {
          ...this.state.formKuota,
          field: field,
        },
      },
      () => console.log(this.state.formKuota)
    );
  }
  async handleSubmitFormKuota() {
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
   // var d = new Date("1/1/1945 " + this.state.formKuota.field.jam_mulai);
  //  var jam_mulai = d.getHours() + ":" + ("0" + d.getMinutes()).slice(-2);
   // formData.append("jam_mulai", jam_mulai);
    formData.append("kuota", this.state.formKuota.field.kuota);
    formData.append("branch_id", this.state.branch_id);
    formData.append("hari", this.state.formKuota.field.hari);
    await axios
      .post(uAPI + "/api/v1/simpanK", formData, config)
      .then((response) => {
        console.log(response);
        this.getData();
        this.addKuota.current.close();
        this.setState({
          formKuota: {
            ...this.state.formKuota,
            field: {
              kuota: "",
              hari:"",
            },
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleConvertHours() {
    let h = moment(this.state.kuota.selection.jam_mulai, "H:mm")
      .locale("en")
      .format("h:mm a");
    return h;
  }

  async handleChangeEditKuota(name, value) {
    let field = Object.assign({}, this.state.editKuota.field);
    field[name] = value;
    await this.setState(
      {
        editKuota: {
          ...this.state.editKuota,
          field: field,
        },
      },
      () => console.log(this.state.editKuota)
    );
  }

  async handleSubmitEditKuota() {
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
   // var d = new Date("1/1/1945 " + this.state.editKuota.field.jam_mulai);
  //  var jam_mulai = d.getHours() + ":" + ("0" + d.getMinutes()).slice(-2);
    formData.append("id_kuota", this.state.kuota.selection.id_kuota);
   // formData.append("jam_mulai", jam_mulai);
    formData.append("kuota", this.state.editKuota.field.kuota);
    formData.append("hari", this.state.editKuota.field.hari);
    formData.append("branch_id", this.state.branch_id);
    await axios
      .post(uAPI + "/api/v1/updateK", formData, config)
      .then((response) => {
        console.log(response);
        this.getData();
        this.updateKuota.current.close();
        this.setState({
          editKuota: {
            ...this.state.editKuota,
            field: {
           //   jam_mulai: "",
              kuota: "",
            },
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async removeToKuota() {
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("id_kuota", this.state.kuota.selection.id_kuota);
    await axios
      .post(uAPI + "/api/v1/deleteK", formData, config)
      .then((response) => {
        console.log(response);
        this.getData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // edit
  getData_() {
    if (this.state.kuota.selection) {
      this.updateKuota.current.open();
      this.setState({
        editKuota: {
          field: {
        //    jam_mulai: moment(this.state.kuota.selection.jam_mulai, "H:mm")
          //    .locale("en")
            //  .format("h:mm a"),
            kuota: this.state.kuota.selection.kuota,
            hari: this.state.kuota.selection.hari,
            branch_id: this.state.kuota.selection.branch_id,
            branch_name: this.state.kuota.selection.branch_name,
          },
        },
      });
    } else {
      alert("Pilih jam yang akan diubah!");
    }
  }
  //hapus
  deleteData() {
    if (this.state.kuota.selection) {
      if (
        window.confirm(
          "Apakah anda yakin menghapus kouta" +
            this.state.kuota.selection.kouta +
            "?"
        )
      ) {
        this.removeToKuota();
      }
    } else {
      alert("Pilih jam yang akan dihapus!");
    }
  }

  async getData() {
    await axios
      .get(uAPI + "/api/v1/listkuota")
      .then(function (response) {
        var input = response.data.results;
        input.sort(function (a, b) {
          if (
            Number(moment(a.jam_mulai, "H").format("H")) <
            Number(moment(b.jam_mulai, "H").format("H"))
          )
            return -1;
          if (
            Number(moment(a.jam_mulai, "H").format("H")) >
            Number(moment(b.jam_mulai, "H").format("H"))
          )
            return 1;
          return 0;
        });
        return input;
      })
      .then((response) => {
        console.log(response);
        this.setState({
          kuota: {
            ...this.state.kuota,
            data: response,
            loading: false,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  updateProgressAndLog(m) {
    // Maximum value out of which percentage needs to be
    // calculated. In our case it's 0 for 0 % and 1 for Max 100%
    // DECIMAL_COUNT specifies no of floating decimal points in our
    // Percentage
    var MAX_PARCENTAGE = 1;
    var DECIMAL_COUNT = 2;

    if (m.status === "recognizing text") {
      var pctg = (m.progress / MAX_PARCENTAGE) * 100;
      this.setState({
        pctg: pctg.toFixed(DECIMAL_COUNT),
      });
    }
  }

  async selectionChange(event) {
    // console.log(event)
    await this.setState(
      {
        kuota: {
          ...this.state.kuota,
          selection: event,
        },
      },
      () => console.log(this.state.kuota.selection.id_kuota)
    );
  }

  changeHandler = (e) => {
    console.log(e.branch_id);
    this.setState({branch_id: e.branch_id});
   };

  async componentDidMount() {
      const option_cabang = []

      await axios
      .get(uAPI + "/api/v1/cabang")
      .then(function (response) {
      return response.data.results;
      })
      .then((response) => {

      this.setState({
      option_cabang : response

      });
      })
      .catch(function (error) {
      console.log(error);
      });
    this.getData();
   

    this.worker = createWorker({
      logger: (m) => this.updateProgressAndLog(m),
    });
  }

  render() {
    return (
      <>
        <Col md={12} style={{ marginLeft: "30px" }}>
          <Layout style={{ width: "100%", height: "100%" }}>
            <LayoutPanel
              region="center"
              title="Kuota Pendaftaran"
              style={{ height: "100%" }}
            >
              <Panel bodyStyle={{ padding: "0px" }}>
                <LinkButton
                  iconCls="icon-add"
                  plain
                  onClick={() => this.addKuota.current.open()}
                >
                  Tambah
                </LinkButton>
                <LinkButton
                  iconCls="icon-edit"
                  plain
                  onClick={() => this.getData_()}
                >
                  Edit
                </LinkButton>
                <LinkButton
                  iconCls="icon-remove"
                  plain
                  onClick={() => this.deleteData()}
                >
                  Hapus
                </LinkButton>
              </Panel>

              <DataGrid
                style={{ height: "100%", width: "100%" }}
                {...this.state.kuota}
                selectionMode="single"
                selection={this.state.kuota.selection}
                onSelectionChange={this.selectionChange}
                onPageChange={this.pageChange}
                columnResizing
                filterable
                virtualScroll
                columnMoving
              >
                <GridColumn
                  field="rn"
                  align="center"
                  width="5px"
                  cellCss="datagrid-td-rownumber"
                  filterable={false}
                  render={({ rowIndex }) => <span>{rowIndex + 1}</span>}
                />
              
              <GridColumn
                  field="branch_name"
                  title="Cabang"
                  align="center"
                  sortable
                  width="10px"
                  render={({ row }) => (
                    <div style={{ padding: "4px 0" }}>
                      <div style={{ fontWeight: "bold" }}>{row.branch_name}</div>
                      {/* <Clipboard data-clipboard-text={row.ktp}>copy</Clipboard> */}
                    </div>
                  )}
                />


            <GridColumn
                  field="hari"
                  title="Cabang"
                  align="center"
                  sortable
                  width="10px"
                  render={({ row }) => (
                    <div style={{ padding: "4px 0" }}>
                      <div style={{ fontWeight: "bold" }}>{row.hari}</div>
                      {/* <Clipboard data-clipboard-text={row.ktp}>copy</Clipboard> */}
                    </div>
                  )}
                />
                

                <GridColumn
                  field="kuota"
                  title="Slot"
                  align="center"
                  sortable
                  width="10px"
                  render={({ row }) => (
                    <div style={{ padding: "4px 0" }}>
                      <div style={{ fontWeight: "bold" }}>{row.kuota}</div>
                      {/* <Clipboard data-clipboard-text={row.ktp}>copy</Clipboard> */}
                    </div>
                  )}
                />
              </DataGrid>
            </LayoutPanel>
          </Layout>
        </Col>

        <Dialog
          title="Tambah"
          style={{ width: "400px", height: "400px", top: "30%" }}
          modal
          closed={true}
          draggable
          ref={this.addKuota}
        >
          <div className="pd-20">
            <h3 className="text-center">Tambah</h3>

            <Form
              ref={this.formKuota}
              style={{ maxWidth: 500 }}
              model={this.state.formKuota.field}
              rules={this.state.formKuota.rules}
              labelWidth={120}
              // labelAlign="right"
              labelPosition="top"
              //floatingLabel
              // validateRules={this.getCustomRules}
              onChange={this.handleChangeFormKuota}
            >
            
            <Select
              name="cabang"
              options={this.state.option_cabang}
              onChange={this.changeHandler}
              getOptionLabel={(option_cabang) =>
               option_cabang.branch_name
              }
              getOptionValue={(option_cabang) => option_cabang.branch_id}
              required="true"
              components={animatedComponents}
              styles={customStyles}
             />

              <FormField name="hari" label="Hari:">
                <TextBox value={this.state.formKuota.field.hari}></TextBox>
              </FormField>

              <FormField name="kuota" label="Kuota:">
                <TextBox value={this.state.formKuota.field.kuota}></TextBox>
              </FormField>

              <FormField>
                <LinkButton onClick={this.handleSubmitFormKuota}>
                  Submit
                </LinkButton>
              </FormField>
            </Form>
          </div>
        </Dialog>

        {/* stay */}
        <Dialog
          title="Edit"
          style={{ width: "400px", height: "400px", top: "30%" }}
          modal
          closed={true}
          draggable
          ref={this.updateKuota}
        >
          <div className="pd-20">
            <h3 className="text-center">Edit</h3>

            <Form
              ref={this.editKuota}
              style={{ maxWidth: 500 }}
              model={this.state.editKuota.field}
              rules={this.state.editKuota.rules}
              labelWidth={120}
              // labelAlign="right"
              labelPosition="top"
              //floatingLabel
              // validateRules={this.getCustomRules}
              onChange={this.handleChangeEditKuota}
            >

            <Select
              name="cabang"
              options={this.state.option_cabang}
              onChange={this.changeHandler}
              getOptionLabel={(option_cabang) =>
               option_cabang.branch_name
              }
              getOptionValue={(option_cabang) => option_cabang.branch_id}
              required="true"
              components={animatedComponents}
              styles={customStyles}
             />

              <FormField name="hari" label="Hari:">
                <TextBox value={this.state.kuota.selection
                      ? this.state.kuota.selection.hari
                      : ""}></TextBox>
              </FormField>
             

              <FormField name="kuota" label="Kuota:">
                <TextBox
                  value={
                    this.state.kuota.selection
                      ? this.state.kuota.selection.kuota
                      : ""
                  }
                ></TextBox>
              </FormField>

              <FormField>
                <LinkButton onClick={this.handleSubmitEditKuota}>
                  Submit
                </LinkButton>
              </FormField>
            </Form>
          </div>
        </Dialog>
      </>
    );
  }
}
export default Kuota;
