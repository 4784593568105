  import React, { Component } from 'react';
  import { createGlobalStyle } from "styled-components";
  import { createPdfFromHtml } from "./logicPrintKartu";
  import { Redirect } from "react-router-dom";
  import Logo from './images/bio_logo.png';
  import {Table,Container,Row,Col} from 'react-bootstrap';
  import queryString from 'query-string';
  import axios from 'axios';
  import { uAPI } from '../config/Config';
  import moment from 'moment'
  import 'moment/locale/id'

  const Global = createGlobalStyle`
    /* paper.css */
    https://github.com/cognitom/paper-css

    /* @page { margin: 0 } */
    #print {
      margin: 0;
      font-family: "Tahoma";
    }
    .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
    }
    
    
    /** Paper sizes **/
    #print.A3               .sheet { width: 297mm; height: 419mm }
    #print.A3.landscape     .sheet { width: 420mm; height: 296mm }
    #print.A4               .sheet { width: 210mm; height: 296mm }
    #print.A4.landscape     .sheet { width: 297mm; height: 209mm }
    #print.A5               .sheet { width: 148mm; height: 209mm }
    #print.A5.landscape     .sheet { width: 210mm; height: 147mm }
    #print.letter           .sheet { width: 216mm; height: 279mm }
    #print.letter.landscape .sheet { width: 280mm; height: 215mm }
    #print.legal            .sheet { width: 216mm; height: 356mm }
    #print.legal.landscape  .sheet { width: 357mm; height: 215mm }
    
    /** Padding area **/
    .sheet.padding-10mm { padding: 10mm }
    .sheet.padding-15mm { padding: 15mm }
    .sheet.padding-20mm { padding: 20mm }
    .sheet.padding-25mm { padding: 25mm }
    
    /** For screen preview **/
    @media screen {
      body {
        font-family: Tahoma,Verdana,Segoe,sans-serif;
        background: #E0E0E0;
        height: 100%;
        width: 100%;
      }
      .sheet {
        background: #FFFFFF;
        /* margin: 5mm auto; */
        padding: 5mm 0;
      }
    }
    
    /** Fix for Chrome issue #273306 **/
    @media print {
      #print.A3.landscape            { width: 420mm }
      #print.A3, #print.A4.landscape { width: 297mm }
      #print.A4, #print.A5.landscape { width: 210mm }
      #print.A5                      { width: 148mm }
      #print.letter, #print.legal    { width: 216mm }
      #print.letter.landscape        { width: 280mm }
      #print.legal.landscape         { width: 357mm }
    }
  `;

  class printScreening extends Component {
      constructor(props) {
          super(props)
          this.state = {
              login: this.props.location.state ? this.props.location.state.login : false,
              detail_login: this.props.location.state ? this.props.location.state.detail_login : null,
              print: '',
              redirect: '',
              query: queryString.parse(this.props.location.search)
          }


      }
      async componentDidMount() {
         window.print();
        console.log('pid',this.state.query) 
        if (this.state.query.pid) {
           const formData = new FormData();
              const config = {
                  headers: {
                      'content-type': 'multipart/form-data'
                  }
              };
              formData.append('patient_id', this.state.query.pid);

              await axios.post(uAPI + '/api/v1/print-kartu', formData, config)
                  .then(function (response) {
                      return response.data.results;
                  })
                  .then(results => {
                      console.log(results);   
                      // <Informed data={results} />
                      this.setState({
                          print: results,
                          //redirect: "/print-kartu"
                      }, () => console.log(this.state.print))
                  })
                  .catch(function (error) {
                      console.log(error);
                  });
        }
       //  await this.setState({
         //    redirect: this.props.location.state.from,
         // })
          
      }


      render(){
        const date= new Date()
        const data = this.state.print;
        if (this.state.redirect !== '') {
              return (
                  <Redirect to={{
                      pathname: this.state.redirect,
                      state: {
                          login: this.state.login,
                          detail_login: this.state.detail_login
                      }
                  }} />
              )


          }
          return(
          <Container>
           <Row>
                   <Col>

                      <div style={{ position: 'absolute', left: '0px', top: '40px' }}>
                          <img src={Logo} alt='logo' width="230" height="50" />
                            
                      </div>

                      {data && data.map(item1 => (
                            <div style={{ position: 'absolute', left: '0px', top: '30px' }}>
                           <img style={{marginLeft:'800px'}} src={'http://localhost:5011/uploads/'+item1.patient_id+'.png'} alt='logo' width="100" height="90" />
                            </div>
                            ))}

                      <br/> <br/> <br/> <br/> <br/><br/> <br/>

                      <h5 style={{textAlign:'center'}}>INFORMASI DAN PERSETUJUAN VAKSINASI COVID-19 (INFORMED CONSENT)</h5>


                      <br/> <br/> <br/> <br/>

                      <p>Yang bertanda tangan di bawah ini,</p>
                      <br/><br/>

                      {data && data.map(item => (
                                               
                            <> 
                      <table style={{fontSize:'20px'}}>
                       
                      <tbody>
                      
                      <tr>
                        <td style={{width: '30%'}}>Nama</td>
                          <td style={{width: '5%'}}>:</td>
                          <td style={{width: '65%'}}>{item.nama}</td>
                        </tr>
                        <tr>
                        
                          <td style={{width: '30%'}}>Jenis Kelamin</td>
                          <td style={{width: '5%'}}>:</td>
                          <td style={{width: '65%'}}>{item.jenis_kelamin}</td>
                        </tr>
                        <tr>
                      
                          <td style={{width: '30%', verticalAlign: 'top'}}>NIK</td>
                          <td style={{width: '5%', verticalAlign: 'top'}}>:</td>
                          <td style={{width: '65%'}}>{item.ktp}</td>
                        </tr>
                        <tr>
                        
                          <td style={{width: '30%'}}>Umur</td>
                          <td style={{width: '5%'}}>:</td>
                          <td style={{width: '65%'}}>{item.umur}</td>
                        </tr>
                        <tr>

                          <td style={{width: '30%'}}>Tempat Kerja</td>
                          <td style={{width: '5%'}}>:</td>
                          <td style={{width: '65%'}}></td>
                        </tr>
                         
                      </tbody>
                     

                        
                      </table>
                       </>
                       ))}

                      <br/><br/>
                       <p>Bersama ini saya menyatakan :</p>
                       <h6 style={{textAlign:'center',fontWeight:'bold'}}>SETUJU / TIDAK SETUJU</h6>
                       <br/>
                       <p>untuk pelaksanaan vaksinasi COVID-19 yang dilakukan dan informasi yang saya nyatakan <br />pada sesi skrining adalah <strong>BENAR</strong>. Demikianlah surat persetujuan ini saya buat dalam keadaan sadar penuh dan menerima atas segala resiko yang terjadi dan tidak akan menuntut pihak manapun.</p>
                        <p>. *)Coret yang tidak perlu</p>
                       <div style={{textAlign:'right'}}>

                       {data && data.map(item => (
                                               
                            <> 
                      
                      <p>Jakarta , {moment(date).format('LLLL')}<br /></p>
                     
                      <p><br /></p>
                      <p>........................,......................</p>
                      <p>{item.nama}</p>
                      <p><br /></p>
                      </>
                      ))}
                      </div>  


                   </Col>
           
             </Row>
  </Container>
          )
      }
  }
         
  export default printScreening;