import React, { Component } from "react";
import bg1 from "./images/bg1.png";
import { Redirect } from 'react-router-dom';
import "./assets/login.scss";
import Loader from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { uAPI } from './config/Config';
import Header from "./Header";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_user: '',
      password: '',
      captha:'',
      rememberMe: false,
      login: localStorage.rememberMe ? true : false,
      detail_login: localStorage.rememberMe ? JSON.parse(localStorage.getItem('detail_login')) : null,
      hidden: true,
      loader: false,
    }

        this.toggleShow = this.toggleShow.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeRemember = this.onChangeRemember.bind(this);
        this.login = this.login.bind(this);

  }

   loginError = async (msg) => await toast.error(msg, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => this.setState({
            loader: false,
        })
    });
    loginSuccess = async (msg) => await toast.success(msg, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => this.setState({
            loader: false,
            login: true,
        })
    });
    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onChangeRemember(e) {
        this.setState({
            rememberMe: e.target.checked
        })
    }

    componentDidMount () {
      loadCaptchaEnginge(6,'white','red','numbers');
   };


    
    async login(e) {
      e.preventDefault();
      await this.setState({
          loader: true,
      })
      let user_captcha = document.getElementById('captha').value;

       if (validateCaptcha(user_captcha)==true) {
        loadCaptchaEnginge(6,'white','red','numbers');
           document.getElementById('captha').value = "";
           if (this.state.email_user === "" && this.state.password === "") {
            
            await this.loginError('Email dan password harus diisi!');
        } else {
         
            await axios.post(uAPI + '/api/v1/auth', {
                email_user: this.state.email_user,
                password: this.state.password
            })
                .then(response => {
                    // console.log("response", response.data)
                    return response.data;

                })
                .then(JsonData => {
                    console.log(JsonData)
                    const status = JsonData.status;
                    if (status !== 200) {
                        this.loginError(JsonData.results);
                    } else {
                        this.setState({
                            detail_login: JsonData.results[0]
                        }, () => console.log(this.state.detail_login))
                        if (this.state.rememberMe) {
                            localStorage.rememberMe = this.state.rememberMe;
                            localStorage.setItem("detail_login", JSON.stringify(this.state.detail_login));
                        }
                        this.loginSuccess('Login berhasil!');
                    }

                })
                .catch(error => {
                    this.loginError('Terjadi Kesalahan!');
                  
                });

        }
       }

       else {
           await this.loginError('Captcha Salah!');
           document.getElementById('captha').value = "";
       }

     
    }

    
    render() {
      if (this.state.login === true && this.state.detail_login) {
          return (<Redirect to={{
              pathname: "/admin",
              state: {
                  login: this.state.login,
                  detail_login: this.state.detail_login
              }
          }} />)
      }
    
      return (
        <>
      <Header />
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-6">
            <h4
              style={{
                fontSize: "70px",
                fontWeight: "bold",
                marginBottom: "20px",
                marginTop: "80px",
                lineHeight: "70px",
                fontFamily: "Tahoma,Verdana,Segoe,sans-serif"
              }}
            >
              BOOKING<br />
              MCU
            </h4>
            <img
              className="d-none d-sm-none d-md-none d-lg-block"
              src={bg1}
              width="100%"
              style={{ marginBottom: "20px" }}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto"
                  style={{
                    backgroundColor: 'rgba(255,255,255, 0.5)',
                    fontWeight: "bold",
                    padding:20,
                    borderRadius:25,
                   }}
                  >
                    <h3 style={{ fontWeight: "bold" }}>User Login</h3>
                    <p className="text-muted mb-4">Welcome Back</p>
                    <form onSubmit={this.login}>
                      <div className="form-group mb-3">
                        <div className="text-center d-flex justify-content-between">
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Email
                          </p>
                        </div>
                        <input
                          id="inputEmail"
                          name="email_user"
                          type="email"
                          placeholder="Email address"
                          required
                          autofocus
                          className="form-control rounded-pill border-0 shadow-sm px-4"
                          style={{ backgroundColor: "#c9d9dd!important" }}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <div className="text-center d-flex justify-content-between mt-4">
                          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Password
                          </p>
                        </div>
                        <input className="form-control rounded-pill border-0 shadow-sm px-4 text-primary" placeholder="************" type={this.state.hidden ? 'password' : 'text'} name="password" id="myInput" onChange={this.onChange} />
                        <br />
                        <input
                          id="captha"
                          name="captha"
                          type="text"
                          placeholder="Enter Captcha"
                          required
                          autofocus
                          className="form-control rounded-pill border-0 shadow-sm px-4"
                          style={{ backgroundColor: "#c9d9dd!important" }}
                          onChange={this.onChange}
                        />
                        <br />
                        <LoadCanvasTemplate />
                        <div className="icon-mata" onClick={this.toggleShow} style={{marginTop:'20px'}}>
                            <img src="https://mis.biomedika.co.id/assets/biomedika_theme/img/image3.png" width="20px" height="10px" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 mx-auto">
                          <div className="custom-control custom-checkbox mb-3">
                            <input type="checkbox" checked={this.state.rememberMe} name="remember" onChange={this.onChangeRemember} /> Remember me
                          </div>
                        </div>
                        <div className="col-6 mx-auto">
                          <a
                            href="#"
                            style={{ fontSize: "12px", float: "right" }}
                          >
                            forgot password
                          </a>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="submit-button text-center">
                          {
                              this.state.loader ? (
                                  <Loader type="Circles" color="#00BFFF" height={40} width={100} />
                              ) : (
                                      <button style={{
                                        backgroundColor: "#c9d9dd",
                                        borderColor: "#c9d9dd",
                                        color: "#000",
                                        fontWeight: "bold",
                                      }} className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm" id="submit" type="submit" >Sign in</button>
                                  )
                          }
                        </div>
                      </div>
                      
                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
                    position="bottom-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        )
    }
}
export default Login;
