const { NODE_ENV: ENV } = process.env
// const APILocal = 'http://' + window.location.hostname + ':5011';
//  const APILocal = 'https://biocore.co.id';
//  const APIProd = 'https://biocore.co.id';

// const APIProd = 'https://reg.biomedika.co.id'+':5011';
// const APIProd = 'http://' + window.location.hostname + ':5011';
const APILocal = 'https://apireg.biomedika.co.id';
const APIProd = 'https://apireg.biomedika.co.id';


export const IMG = 'https://mis.biomedika.co.id/uploads/avatar/';
export const uTester = 'https://mis.biomedika.co.id/';
export const uAPI = ENV === 'production' ? APIProd : APILocal;