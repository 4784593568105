import React from "react";
import { Link, Redirect, Route, Switch, withRouter } from "react-router-dom";
import "./assets/sidebar.css";
import back from "../images/back.svg";
import $ from "jquery";
import PostType from './PostType';
import Dashboard from './Dashboard';
import { Container,Row,Breadcrumb,Col,Card,Button  } from 'react-bootstrap';


class Sidebar extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
            login: this.props.location.state ? this.props.location.state.login : false,
            detail_login: this.props.location.state ? this.props.location.state.detail_login : null,
            path: this.props.history.location.pathname,
            menuLogout: false,
        }
        this.LogoutClick = this.LogoutClick.bind(this);
        this.HideLogout = this.HideLogout.bind(this);
        this.Logout = this.Logout.bind(this);
        this.active = this.active.bind(this);
    }


  LogoutClick() {
        this.setState({
            menuLogout: !this.state.menuLogout,
        })
    }
    HideLogout() {
        this.setState({
            menuLogout: false,
        });
    }
    async Logout() {
        await localStorage.removeItem("rememberMe");
        await this.setState({
            login: false,
        })
    }
    active() {
        this.setState((state, props) => {
            return {
                path: props.location.pathname,
            };
        }, () => console.log(this.state.path))
    }

  componentDidMount() {
    $(window)
      .on("resize", function () {
        if ($(this).width() < 760) {
          $("tr td:first-child").click(function () {
            $(this).siblings().css({ display: "inline-block" });

            var $this = $(this);
            setTimeout(function () {
              $this.siblings().css("transform", "translateY(0)");
            }, 0);

            $("tr td:first-child")
              .not($(this))
              .siblings()
              .css({ display: "none", transform: "translateY(-9999px)" });
          });
        } else if ($(this).width() > 760) {
          //unbind click : name is not clickable when screen is > 700px
          $("tr td:first-child").unbind("click");
          //remove with jquery added styles
          $("tr td:first-child").siblings().css({ display: "", transform: "" });
        }
      })
      .resize();
    $(function () {
      // Sidebar toggle behavior
      $("#sidebarCollapse").on("click", function () {
        $("#sidebar, #content").toggleClass("active");
        $(this).toggleClass("rotate");
       
      });
      $(".nav-item").on("click", function () {
        
        $(".nav-item").removeClass("active");
        $(this).addClass("active");
      });
    });

   

  }


  render() {
     if (this.state.login === false  || this.state.detail_login === null) {
            return (<Redirect to="" />)
        }
      const match = this.props.match.path;
      return (
       <>
          <div>
            {/* Vertical navbar */}
            <div className="vertical-nav" id="sidebar">
              <ul className="nav flex-column mb-0" style={{ marginTop: "40px" }}>
                <li className={"nav-item " + (this.state.path === '/admin' ? 'active' : '')}>
                <Link to={`${this.props.match.path}`} onClick={this.active} className="nav-link" type="button">
                    <span>
                        <i className="fa fa-home"></i> Dashboard
                    </span>
                </Link>
                </li>
                <li className={"nav-item " + (this.state.path === '/admin/pendaftaran' ? 'active' : '')}>
                <Link to={`${this.props.match.path}/pendaftaran`} onClick={this.active} className="nav-link" type="button">
                    <span>
                        <i className="fa fa-id-card-o"></i> Pendaftaran
                    </span>
                </Link>
                </li>
                
                { this.state.detail_login.level == '1' ? <li className={"nav-item " + (this.state.path === '/admin/kuota' ? 'active' : '')}>
                <Link to={`${this.props.match.path}/kuota`} onClick={this.active} className="nav-link" type="button">
                    <span>
                        <i className="fa fa-clock-o"></i> Kuota
                    </span>
                </Link>
                </li> : null }
                
                { this.state.detail_login.level == '1' ? 
                <li className={"nav-item " + (this.state.path === '/admin/reminder' ? 'active' : '')}>
                <Link to={`${this.props.match.path}/reminder`} onClick={this.active} className="nav-link" type="button">
                    <span>
                        <i className="fa fa-envelope-square"></i> Reminder
                    </span>
                </Link>
                </li>
                : null }
                
                <li className="nav-item" onClick={this.HideLogout}>
                   <Link to="#" onClick={this.Logout} className="nav-link" type="button">
                    <i className="fa fa-sign-out"></i> Log Out
                   </Link>
                </li>
                      
              </ul>
              <div id="sidebarCollapse" type="button" className="rotate">
                <img src={back} />
              </div>
            </div>
            {/* End vertical navbar */}
          </div>
     
      <Container  style={{ padding: '20px'}}>
        <Row style={{width: '120%'}}>
          
              <Switch>
                      <Route path={`${match}/:postType`}>
                          <PostType detail_login={this.state.detail_login} />
                      </Route>
                      <Route path={match}>
                          <Dashboard detail_login={this.state.detail_login} login={this.state.login} />
                      </Route>
              </Switch>
             
           </Row>
        </Container>
     
      </>
    );
  }
}
export default withRouter(Sidebar);
