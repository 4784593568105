import React, { Component } from 'react';
import { createGlobalStyle } from "styled-components";
import { createPdfFromHtml } from "./logicPrintKartu";
import { Redirect } from "react-router-dom";
import Logo from './images/bio_logo.png';
import {Table,Container,Row,Col} from 'react-bootstrap';
import queryString from 'query-string';
import axios from 'axios';
import { uAPI } from '../config/Config';
import moment from 'moment'
import 'moment/locale/id'

const Global = createGlobalStyle`
  /* paper.css */
  https://github.com/cognitom/paper-css

  /* @page { margin: 0 } */
  #print {
    margin: 0;
    font-family: "Tahoma";
  }
  .sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
  }
  
  
  /** Paper sizes **/
  #print.A3               .sheet { width: 297mm; height: 419mm }
  #print.A3.landscape     .sheet { width: 420mm; height: 296mm }
  #print.A4               .sheet { width: 210mm; height: 296mm }
  #print.A4.landscape     .sheet { width: 297mm; height: 209mm }
  #print.A5               .sheet { width: 148mm; height: 209mm }
  #print.A5.landscape     .sheet { width: 210mm; height: 147mm }
  #print.letter           .sheet { width: 216mm; height: 279mm }
  #print.letter.landscape .sheet { width: 280mm; height: 215mm }
  #print.legal            .sheet { width: 216mm; height: 356mm }
  #print.legal.landscape  .sheet { width: 357mm; height: 215mm }
  
  /** Padding area **/
  .sheet.padding-10mm { padding: 10mm }
  .sheet.padding-15mm { padding: 15mm }
  .sheet.padding-20mm { padding: 20mm }
  .sheet.padding-25mm { padding: 25mm }
  
  /** For screen preview **/
  @media screen {
    body {
      font-family: Tahoma,Verdana,Segoe,sans-serif;
      background: #E0E0E0;
      height: 100%;
      width: 100%;
    }
    .sheet {
      background: #FFFFFF;
      /* margin: 5mm auto; */
      padding: 5mm 0;
    }
  }
  
  /** Fix for Chrome issue #273306 **/
  @media print {
    #print.A3.landscape            { width: 420mm }
    #print.A3, #print.A4.landscape { width: 297mm }
    #print.A4, #print.A5.landscape { width: 210mm }
    #print.A5                      { width: 148mm }
    #print.letter, #print.legal    { width: 216mm }
    #print.letter.landscape        { width: 280mm }
    #print.legal.landscape         { width: 357mm }
  }
`;

class printKartu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            login: this.props.location.state ? this.props.location.state.login : false,
            detail_login: this.props.location.state ? this.props.location.state.detail_login : null,
            print: '',
            redirect: '',
            query: queryString.parse(this.props.location.search)
        }


    }
    async componentDidMount() {
       window.print();
      console.log('pid',this.state.query) 
      if (this.state.query.pid) {
         const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            formData.append('patient_id', this.state.query.pid);

            await axios.post(uAPI + '/api/v1/print-kartu', formData, config)
                .then(function (response) {
                    return response.data.results;
                })
                .then(results => {
                    console.log(results);   
                    // <Informed data={results} />
                    this.setState({
                        print: results,
                        //redirect: "/print-kartu"
                    }, () => console.log(this.state.print))
                })
                .catch(function (error) {
                    console.log(error);
                });
      }
     //  await this.setState({
       //    redirect: this.props.location.state.from,
       // })
        
    }


    render(){
      const data = this.state.print;
      if (this.state.redirect !== '') {
            return (
                <Redirect to={{
                    pathname: this.state.redirect,
                    state: {
                        login: this.state.login,
                        detail_login: this.state.detail_login
                    }
                }} />
            )


        }
        return(
        <Container>
         <Row>
                 <Col>

                    <div style={{ position: 'absolute', left: '0px', top: '40px' }}>
                        <img src={Logo} alt='logo' width="230" height="50" />
                    </div>

                    <br/> <br/> <br/> <br/> <br/>

                         <h3 style={{textAlign:'center'}}>KARTU KENDALI PELAYANAN VAKSINASI COVID-19</h3>


                    <br/>

                    
                     <h5 style={{textAlign:'left',fontFamily:'Tahoma'}}>A.  MEJA PRA-REGISTRASI</h5>
                     <Table bordered>
                      <thead>
                        <tr>
                          <th>VERIFIKASI DATA IDENTITAS</th>
                          <th></th>
                          <th>Paraf petugas</th>
                        </tr>
                      </thead>
                      <tbody>
                      {data && data.map(item => (
                                               
                            <>                 
                        <tr>
                          <td>Nama</td>
                          <td width="50%">{item.nama}</td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>NIK</td>
                          <td width="50%">{item.ktp}</td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>Tgl Lahir</td>
                          <td width="50%">{moment(item.tgl_lahir).format('LL')}</td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>No. Hp</td>
                          <td width="50%"></td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>Alamat</td>
                          <td width="50%">{item.alamat}</td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>Vaksin yang diberikan pada dosis 1</td>
                          <td width="50%"></td>
                          <td></td>
                        </tr>
                        </>

                         ))}
                        
                      </tbody>
                    </Table>

                    <br/>

                     <h5 style={{textAlign:'left',fontFamily:'Tahoma'}}>B. MEJA 1 (SKRINING DAN VAKSINASI)</h5>

                     <Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Pemeriksaan</th>
                          <th>Hasil</th>
                          <th>Tindak Lanjut</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Suhu</td>
                          <td></td>
                          <td>Suhu > 37,5 0C vaksinasi ditunda sampai sasaran sembuh</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Tekanan Darah</td>
                          <td></td>
                          <td>Jika tekanan darah >180/110 mmHg pengukuran tekanan darah diulang 5 (lima) sampai 10 (sepuluh) menit kemudian Jika masih tinggi maka vaksinasi ditunda sampai terkontrol</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>Pertanyaan</td>
                          <td>Ya</td>
                          <td>Tidak</td>
                        </tr>

                         <tr>
                          <td>1</td>
                          <td>Pertanyaan untuk vaksinasi ke-1
Apakah Anda memiliki riwayat alergi berat seperti sesak napas, bengkak dan urtikaria seluruh badan atau reaksi berat lainnya karena vaksin?
</td>
                          <td></td>
                          <td>Jika Ya: vaksinasi diberikan di Rumah Sakit </td>
                        </tr>

                         <tr>
                          <td></td>
                          <td>Pertanyaan untuk vaksinasi ke-2
Apakah Anda memiliki riwayat alergi berat setelah divaksinasi COVID-19 sebelumnya?
</td>
                          <td></td>
                          <td>Jika Ya: merupakan kontraindikasi untuk vaksinasi ke-2</td>
                        </tr>

                        <tr>
                          <td>2</td>
                          <td>Apakah Anda sedang hamil
</td>
                          <td></td>
                          <td>Jika sedang hamil vaksinasi ditunda sampai melahirkan</td>
                        </tr>


                         <tr>
                          <td>3</td>
                          <td>Apakah Anda mengidap penyakit autoimun seperti asma, lupus.
</td>
                          <td></td>
                          <td>Jika Ya, maka vaksinasi ditunda jika sedang dalam kondisi akut atau  belum terkendali</td>
                        </tr>

                         <tr>
                          <td>4</td>
                          <td>Apakah Anda sedang mendapat pengobatan untuk gangguan pembekuan darah, kelainan darah, defisiensi imun dan penerima produk darah/transfusi?
</td>
                          <td></td>
                          <td>Jika Ya: vaksinasi ditunda dan dirujuk</td>
                        </tr>

                        <tr>
                          <td>5</td>
                          <td>Apakah Anda sedang mendapat pengobatan immunosupressant seperti kortikosteroid dan kemoterapi?</td>
                          <td></td>
                          <td>Jika Ya: vaksinasi ditunda dan dirujuk</td>
                        </tr>

                        <tr>
                          <td>6</td>
                          <td>Apakah Anda memiliki penyakit jantung berat dalam keadaan sesak?</td>
                          <td></td>
                          <td>Jika Ya: vaksinasi ditunda dan dirujuk</td>
                        </tr>

                        <tr>
                            <td colspan="4" style={{textAlign:'center'}}>Pertanyaan Nomor 7 dilanjutkan apabila terdapat penilaian kelemahan fisik pada sasaran vaksinasi.</td>
                        </tr>

                        <tr>
                          <td>7</td>
                          <td> <p style={{margin: '0cm', fontSize: '16px',  marginBottom: '3.0pt', lineHeight: '115%'}}><span style={{fontSize: '11px', lineHeight: '115%', fontFamily: '"Arial",sans-serif', color: 'black'}}>Pertanyaan tambahan bagi sasaran lansia (≥60 tahun):</span></p>
        <ol style={{listStyleType: 'decimal'}}>
          <li><span style={{color: 'black', fontSize: '8.0pt'}}>Apakah Anda&nbsp;</span><span style={{lineHeight: '115%', fontFamily: '"Arial",sans-serif', fontSize: '8.0pt', color: 'black'}}>mengalami kesulitan untuk naik 10 anak tangga?</span></li>
          <li><span style={{color: 'black', fontSize: '8.0pt'}}>Apakah Anda&nbsp;</span><span style={{lineHeight: '115%', fontFamily: '"Arial",sans-serif', fontSize: '8.0pt', color: 'black'}}>sering merasa kelelahan?</span></li>
          <li><span style={{color: 'black', fontSize: '8.0pt'}}>Apakah Anda&nbsp;</span><span style={{lineHeight: '115%', fontFamily: '"Arial",sans-serif', fontSize: '8.0pt', color: 'black'}}>memiliki paling sedikit 5 dari 11 penyakit (Hipertensi, diabetes, kanker, penyakit paru kronis, serangan jantung, gagal jantung kongestif, nyeri dada, asma, nyeri sendi, stroke dan penyakit ginjal)?</span></li>
          <li><span style={{color: 'black', fontSize: '8.0pt'}}>Apakah Anda&nbsp;</span><span style={{lineHeight: '115%', fontFamily: '"Arial",sans-serif', fontSize: '8.0pt', color: 'black'}}>mengalami kesulitan berjalan kira-kira 100 sampai 200 meter?</span></li>
        </ol>
        <p><span style={{fontSize: '11px', fontFamily: '"Arial",sans-serif', color: 'black'}}>Apakah Anda mengalami p</span><span style={{fontSize: '11px', fontFamily: '"Arial",sans-serif', color: 'black'}}>enurunan berat badan yang bermakna dalam setahun terakhir?</span></p>
</td>
                          <td></td>
                          <td>Jika terdapat 3 atau lebih jawaban Ya maka vaksin tidak dapat diberikan</td>
                        </tr>

                        <tr>
                            <td colspan="3"><p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '3.0pt', lineHeight: '115%'}}><strong><span style={{fontSize: '11px', lineHeight: '115%', fontFamily: 'Tahoma, Geneva, sans-serif', color: 'black'}}>HASIL SKRINING :&nbsp;</span></strong></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginRight: '0cm', marginBottom: '3.0pt', marginLeft: '22.4pt', lineHeight: '115%'}}><span style={{fontFamily: 'Tahoma,Geneva, sans-serif'}}><strong><span style={{fontSize: '11px', lineHeight: '115%', color: 'black'}}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAI0lEQVQoFWNgYGD4x8DA8J8EDFLP8J8UADV8VBP9A4LkyAUAGSOIgkZnDGEAAAAASUVORK5CYII=" style={{float: 'left', textAlign: 'left', display: 'inline-block',margin: '5px 5px 5px 0px'}} />&nbsp; &nbsp;LANJUT VAKSIN</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginRight: '0cm', marginBottom: '3.0pt', marginLeft: '22.4pt', lineHeight: '115%'}}><span style={{fontFamily: 'Tahoma,Geneva, sans-serif'}}><strong><span style={{fontSize: '11px', lineHeight: '115%', color: 'black'}}><strong style={{fontWeight: 700, color: 'rgb(0, 0, 0)', fontFamily: 'Tahoma, Geneva, sans-serif', fontSize: '16px', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', letterSpacing: 'normal', orphans: 2, textAlign: 'start', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationThickness: 'initial', textDecorationStyle: 'initial', textDecorationColor: 'initial'}}><span style={{fontSize: '11px', lineHeight: '12.65px', color: 'black'}}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAI0lEQVQoFWNgYGD4x8DA8J8EDFLP8J8UADV8VBP9A4LkyAUAGSOIgkZnDGEAAAAASUVORK5CYII=" style={{textAlign: 'left', position: 'relative', maxWidth: 'calc(100% - 5px)', cursor: 'pointer', display: 'inline-block', float: 'left', verticalAlign: 'bottom', margin: '5px 5px 5px 0px'}} /></span></strong>&nbsp; &nbsp;TUNDA</span></strong></span></p>
        <p><strong><span style={{fontSize: '11px', fontFamily: 'Tahoma, Geneva, sans-serif', color: 'black'}}>TIDAK DIBERIKAN</span></strong></p></td>
                            <td>Paraf Petugas : </td>

                        </tr>

                        <tr>
                            <td colspan="4" ><h3>HASIL VAKSINASI </h3></td>
                        </tr>

                        <tr>
                                <td colspan="2"><p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '3.0pt', lineHeight: '115%'}}><span style={{fontSize: '11px', lineHeight: '115%', fontFamily: 'Tahoma, Geneva, sans-serif', color: 'black'}}>Jenis Vaksin:</span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '3.0pt', lineHeight: '115%'}}><span style={{fontFamily: 'Tahoma,Geneva, sans-serif'}}><span style={{fontSize: '11px', lineHeight: '115%', color: 'black'}}>No. Batch:</span></span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginBottom: '3.0pt', lineHeight: '115%'}}><span style={{fontFamily: 'Tahoma,Geneva, sans-serif'}}><span style={{fontSize: '11px', lineHeight: '115%', color: 'black'}}>Tanggal vaksinasi:</span></span></p>
        <p><span style={{fontSize: '11px', fontFamily: 'Tahoma, Geneva, sans-serif', color: 'black'}}>Jam Vaksinasi:</span></p></td>

                                <td width="30%"></td>
                                <td>Paraf Petugas :</td>
                        </tr> 

                      </tbody>
                    </Table>

                    <br />


                    <h5 style={{textAlign:'left',fontFamily:'Tahoma'}}>C.   MEJA 2:  PENCATATAN DAN OBSERVASI</h5>
                     <Table bordered>
                      <thead>
                        <tr>
                          <th colspan="2">HASIL OBSERVASI</th>
                      
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginRight: '0cm', marginBottom: '3.0pt', marginLeft: '22.4pt', lineHeight: '115%'}}><span style={{fontFamily: 'Tahoma,Geneva, sans-serif'}}><strong><span style={{fontSize: '11px', lineHeight: '115%', color: 'black'}}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAI0lEQVQoFWNgYGD4x8DA8J8EDFLP8J8UADV8VBP9A4LkyAUAGSOIgkZnDGEAAAAASUVORK5CYII=" style={{float: 'left', textAlign: 'left', display: 'inline-block',margin: '5px 5px 5px 0px'}} />&nbsp; &nbsp;Tanpa Keluhan</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '16px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginRight: '0cm', marginBottom: '3.0pt', marginLeft: '22.4pt', lineHeight: '115%'}}><span style={{fontFamily: 'Tahoma,Geneva, sans-serif'}}><strong><span style={{fontSize: '11px', lineHeight: '115%', color: 'black'}}><strong style={{fontWeight: 700, color: 'rgb(0, 0, 0)', fontFamily: 'Tahoma, Geneva, sans-serif', fontSize: '16px', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', letterSpacing: 'normal', orphans: 2, textAlign: 'start', textIndent: '0px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationThickness: 'initial', textDecorationStyle: 'initial', textDecorationColor: 'initial'}}><span style={{fontSize: '11px', lineHeight: '12.65px', color: 'black'}}><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAI0lEQVQoFWNgYGD4x8DA8J8EDFLP8J8UADV8VBP9A4LkyAUAGSOIgkZnDGEAAAAASUVORK5CYII=" style={{textAlign: 'left', position: 'relative', maxWidth: 'calc(100% - 5px)', cursor: 'pointer', display: 'inline-block', float: 'left', verticalAlign: 'bottom', margin: '5px 5px 5px 0px'}} /></span></strong>&nbsp; &nbsp;Ada keluhan
Sebutkan keluhan jika ada,… ……..
</span></strong></span></p>
<br/>
<br/><br/>
</td>
                          <td width="50%"></td>
                        </tr>

                        
                      </tbody>
                    </Table>


                 </Col>
         
           </Row>
</Container>
        )
    }
}
       
export default printKartu;