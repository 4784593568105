import React, { Component } from "react";
import bg1 from "./images/bg1.png";
import "./assets/login.scss";
import Loader from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { uAPI } from './config/Config';
import Header from "./Header";
import swal from 'sweetalert';
import queryString from 'query-string';





class Book extends Component {
constructor(props) {
super(props);
this.state = {
         
            print: '',
            redirect: '',
            patient_id:'',
            jam_mulai:'',
            tgl_periksa:'',
            query: queryString.parse(this.props.location.search)
        }


this.saveBook = this.saveBook.bind(this);
this.onChange = this.onChange.bind(this);

}

onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


async saveBook(e) {
// console.log(e)
await this.setState({
patient_id: e.target.value,
loader: true,
})
const formData = new FormData();
const config = {
headers: {
'content-type': 'multipart/form-data'
}
};
formData.append('patient_id', this.state.query.pid);
formData.append('tgl_periksa', this.state.tgl_periksa);
formData.append('jam_mulai', this.state.jam_mulai);

if (formData.get('patient_id') !== null || formData.get('patient_id') !== "") {
await axios.put(uAPI + '/api/v1/book', formData, config)
.then(response => {

    if (response.data.status === 406) {
        this.setState({
            loader: false,
            patient_id: "",
            errorMessage: response.data.message,
        })

                                            
    } else {
        this.setState({
            loader: false,
            patient_id: "",
            errorMessage: response.data.message,
        })
        
    }

    swal({
    title: "Booking",
    text: "Data telah di simpan!",
    icon: "success",
    timer: 1000,
    button: "Terima Kasih!"
    });


 
   



})
.catch(error => {
    console.log(error);
    this.setState({
        loader: false,
        patient_id: "",
        errorMessage: "Terjadi Kesalahan. Silahkan dicoba lagi.",
    })
    
})

}

}


async componentDidMount(){

console.log('pid',this.state.query) 
if (this.state.query.pid) {
const formData = new FormData();
const config = {
headers: {
'content-type': 'multipart/form-data'
}
};
formData.append('patient_id', this.state.query.pid);


await axios.post(uAPI + '/api/v1/print-kartu', formData, config)
.then(function (response) {
return response.data.results;
})
.then(results => {
console.log(results);   
// <Informed data={results} />
this.setState({
print: results,
//redirect: "/print-kartu"
}, () => console.log(this.state.print))
})
.catch(function (error) {
console.log(error);
});
}
}



render() {
  const data = this.state.print;
return (
<>
<Header />
<div className="container-fluid">
<div className="row no-gutter">
  <div className="col-md-6">
    <h4
      style={{
        fontSize: "70px",
        fontWeight: "bold",
        marginBottom: "20px",
        marginTop: "80px",
        marginLeft: "10px",
        lineHeight: "70px",
        fontFamily: "Tahoma,Verdana,Segoe,sans-serif"
      }}
    >
      Booking <br />Vaksinasi<br />
      
    </h4>
    <img
      className="d-none d-sm-none d-md-none d-lg-block"
      src={bg1}
      width="100%"
      style={{ marginBottom: "20px" }}
      alt=""
    />
  </div>
  <div className="col-md-6">
    <div className="login d-flex align-items-center py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-xl-7 mx-auto">
            <h1 style={{ fontWeight: "bold",textAlign:"center" }}>Pilih Tanggal dan Jam kedatangan</h1>
            <br/> <br/> <br/>
    
     
    <form onSubmit={this.saveBook}>
    {data && data.map(item => (
      <> 
    <div className="text-center d-flex justify-content-between">
    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
    Nama Anda
    </p>
    </div>
    <input
    id="inputEmail"
    type="text"
    value={item.nama}
    readonly="true"
    className="form-control rounded-pill border-0 shadow-sm px-4"
    style={{ backgroundColor: "#c9d9dd!important" }}
    /><br/>
    <div className="text-center d-flex justify-content-between">
    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
    Patient ID
    </p>
    </div>
    <input
    id="inputEmail"
    name="patient_id"
    type="text"
    value={item.patient_id}
    readonly="true"
    className="form-control rounded-pill border-0 shadow-sm px-4"
    style={{ backgroundColor: "#c9d9dd!important" }}
    /><br/>
      <div className="form-group mb-3">
        <div className="text-center d-flex justify-content-between">
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            Tanggal
          </p>
        </div>
        <input
          name="tgl_periksa"
          onChange={this.onChange}
          type="date"          
          required
          autofocus
          className="form-control rounded-pill border-0 shadow-sm px-4"
          style={{ backgroundColor: "#c9d9dd!important" }}
        />
      </div>
      <div className="form-group mb-3">
        <div className="text-center d-flex justify-content-between mt-4">
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            Jam
          </p>
        </div>
        <input className="form-control rounded-pill border-0 shadow-sm px-4 text-primary" type="time" name="jam_mulai"  onChange={this.onChange}  />
       
      </div>
     

      <div className="col-md-12">
        <div className="submit-button text-center">
          {
              this.state.loader ? (
                  <Loader type="Circles" color="#00BFFF" height={40} width={100} />
              ) : (
                      <button style={{
                        backgroundColor: "#c9d9dd",
                        borderColor: "#c9d9dd",
                        color: "#000",
                        fontWeight: "bold",
                      }} className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm" id="submit" type="submit" >Kirim</button>
                  )
          }
        </div>
      </div>
       </>
    ))}
    </form>
   
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

    </>

)
}
}
export default Book;
