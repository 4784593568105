import React, { Component } from "react";
import bg1 from "./images/bg1.png";
import "./assets/login.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { uAPI } from './config/Config';
import Header from "./Header";
import swal from 'sweetalert';
import QrReader from 'react-barcode-reader'


class Scan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qr_text:"Tidak di temukan'",
      loader: false,
      delay: 100,
    }


     this.scan = React.createRef();
     this.qrChange = this.qrChange.bind(this);

   }


   async qrChange(data) {
        // console.log(e)
        await this.setState({
            qr_text: data,
            loader: true,
        })
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
       await formData.append('patient_id', this.state.qr_text);
       await localStorage.setItem('pidnew', this.state.qr_text);

        if (formData.get('patient_id') !== null || formData.get('patient_id') !== "") {
            await axios.put(uAPI + '/api/v1/qrcode', formData, config)
                .then(response => {

                    if (response.data.status === 406) {
                        this.setState({
                            loader: false,
                            qr_text: "",
                            errorMessage: response.data.message,
                        })

                                                            
                    } else {
                        this.setState({
                            loader: false,
                            qr_text: "",
                            errorMessage: response.data.message,
                        })
                        
                    }

                    swal({
                    title: "Scan",
                    text: "Data telah di simpan!",
                    icon: "success",
                    timer: 2000,
                    button: "Terima Kasih!"
                    });

                    this.scan.current.focus();

                    window.location.reload(false);

                   


                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        qr_text: "",
                        errorMessage: "Terjadi Kesalahan. Silahkan dicoba lagi.",
                    })
                    
                })

        }

     }

  
    handleError = err => {
      console.error(err)
    }


    componentDidMount(){

        localStorage.getItem('pidnew');
      
    }

    

    render() {
       const pid = localStorage.getItem('pidnew');
       const previewStyle = {
        height: 240,
        width: 320,
      }

                return (
                <>
                <Header />
                <div className="container-fluid">
                <div className="row no-gutter">
                  <div className="col-md-6">
                    <h4
                      style={{
                        fontSize: "70px",
                        fontWeight: "bold",
                        marginBottom: "20px",
                        marginTop: "80px",
                         marginLeft: "80px",
                        lineHeight: "70px",
                        fontFamily: "Tahoma,Verdana,Segoe,sans-serif"
                      }}>
                      SCAN DISINI<br /><br />
                      
                    </h4>
                    <img
                      className="d-none d-sm-none d-md-none d-lg-block"
                      src={bg1}
                      width="100%"
                      style={{ marginBottom: "20px" }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="login d-flex align-items-center py-5">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-10 col-xl-7 mx-auto">
                            <h1 style={{ fontWeight: "bold",textAlign:"center" }}>Scan Pendaftaran</h1>
                            <br/> <br/> <br/>
                            <form onSubmit={this.login}>
                              <div className="form-group mb-3">
                                
                              <QrReader
                                delay={this.state.delay}
                                style={previewStyle}
                                onError={this.handleError}
                                onScan={this.qrChange}
                                />


                              </div>
                              
                              
                            </form>
                            <br/>
                            <h5>Scan QR Code ini untuk melihat antrian langsung:</h5>
                             <br/>
                         
                            <center><img src={uAPI+"/uploads/"+pid+".png"} width="300" height="300" /></center>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <ToastContainer
                            position="bottom-right"
                            autoClose={1000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                 
            </>
        )
    }
}
export default Scan;
